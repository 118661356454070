import React from 'react';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';

type BasicCardProps = {
  cardKey: string;
  children?: React.ReactNode;
  onCardClicked?: (cardKey: string) => void
} & React.AllHTMLAttributes<HTMLDivElement>

export const BasicCard: React.FC<BasicCardProps> = (
  {
    children,
    cardKey,
    onCardClicked = () => ({}),
    ...restAttr
  },
) => (
  <MuiCard aria-label={ cardKey } onClick={ () => onCardClicked(cardKey) } { ...restAttr }>
    <MuiCardContent>
      { children }
    </MuiCardContent>
  </MuiCard>
);
