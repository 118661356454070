type CookieOptions = {
  expires?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
}

/**
 * Gets a cookie
 *
 * @param name The name of the cookie to retrieve.
 * @returns a string that represents the cookie value, or null when it is empty or
 * does not exist.
 */
export function getCookie(name: string) {
  const re = new RegExp(name + '=([^;]+)');
  const value = re.exec(document.cookie);
  return value != null ? decodeURIComponent(value[1]) : '';
}

/**
 * Sets a cookie based on a given name, value and additional options.
 *
 * @param name The name of the cookie to set
 * @param value The value of the new cookie.
 * @param options An object literal containing key/value pairs to provide optional cookie
 * attributes.
 * @param options.expires Either an integer specifying the expiration date from now on
 * in days.
 * @param options.path The value of the path attribute of the cookie (default: path of
 * page that created the cookie).
 * @param options.domain The value of the domain attribute of the cookie (default: domain
 * of page that created the cookie).
 * @param options.secure If true, the secure attribute of the cookie will be set and the
 * cookie transmission will require a secure protocol (like HTTPS).
 */
export function setCookie(name: string, value: string | null, options: CookieOptions = {}) {
  const domainArr = window.location.hostname.split('.');

  let domain = domainArr.length > 2
    ? domainArr[domainArr.length - 2] + '.' + domainArr[domainArr.length - 1]
    : window.location.hostname;
  domain = domain === 'vercel.app' ? window.location.hostname : domain;

  let expire = Date.now() + 3600 * 1000 * 24 * 365 * 10;

  if (options.expires) {
    expire = Date.now() + (options.expires * 24 * 60 * 60 * 1000);
  }

  const nameValStr = `${ name }=${ encodeURIComponent(value || '') };`;
  const domainStr = `Domain=${ options.domain || domain };`;
  const pathStr = `Path=${ options.path || '/' };`;
  const expireStr = `Expires=${ new Date(expire).toUTCString() };`;
  const secureStr = `${ options.secure ? 'Secure' : '' }`;

  document.cookie = [nameValStr, domainStr, pathStr, expireStr, secureStr].join('');
}

export function removeCookie(name: string) {
  const expiredDate = new Date('Thu, 01 Jan 1970 00:00:00 UTC').toUTCString();
  const cookieToRemove = `${ name }=; expires=${ expiredDate }; path=/;`;

  document.cookie = cookieToRemove;
}

export function parseCookieObj(cookieVal: string): Record<string, unknown> | null {
  if (!cookieVal) return null;

  try {
    const tempCookieValue = cookieVal.replace((/([\w]+)(:)/g), '"$1"$2');
    const parsedCookieValue = tempCookieValue.replace((/'/g), '"');

    return JSON.parse(parsedCookieValue);
  } catch (e) {
    return null;
  }
}

export function getMainDomain() {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'timedoctor.com'
    : 'timedoctortest.com';
}

function getQueryParam(paramName: string): string | null {
  if (typeof window === 'undefined' || typeof URLSearchParams === 'undefined') {
    return null;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const hasUtmParams = Array.from(urlParams.keys()).some((key) => key.startsWith('utm_'));

  if (hasUtmParams) {
    setCookie(paramName, urlParams.get(paramName));
  }

  return urlParams.get(paramName);
}

function getUTMsByName(name: string) {
  return getCookie(name) || getQueryParam(name) || null;
}

function getUTMs() {
  return {
    name: getUTMsByName('utm_campaign'),
    source: getUTMsByName('utm_source'),
    medium: getUTMsByName('utm_medium'),
    term: getUTMsByName('utm_term'),
    content: getUTMsByName('utm_content'),
    keyword: getUTMsByName('utm_keyword'),
    adgroup: getUTMsByName('adgroup'),
  };
}

const cookieUtils = {
  getCookie,
  setCookie,
  removeCookie,
  getUTMsByName,
  getUTMs,
};

export default cookieUtils;
