import type { AllowedColors, SimplePaletteColorOptions } from '@mui/material';
import type { Theme } from '@mui/material/styles';

export const utils: Theme['utils'] = {
  spacing: (spacing) => {
    if (typeof spacing === 'number') {
      return `${ spacing * 8 }px`;
    }

    return spacing;
  },
  getPaletteColor: (theme, color) => {
    const [colorKey, colorOption] = color.split('.') as [keyof AllowedColors, keyof SimplePaletteColorOptions];
    return theme.palette[colorKey][colorOption];
  },
};

export const skipPropForwardingFn = <CustomProps extends Record<string, unknown>>(
  propsToSkip: Array<keyof CustomProps>,
  prop: PropertyKey,
): boolean => !propsToSkip.includes(prop as string);
