import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompanyTrackingMode, CurrentStep } from '../../types';
import { RootState } from '../store';

import { selectCompanyName } from './authSlice';

export type OnboardingState = {
  currentStep: CurrentStep;
  isEUMember: boolean;
  trackingMode: CompanyTrackingMode;
  inviteSuccessCount: number;
  hasCompletedOnboarding: boolean;
  wasInitiated: boolean;
  showCcOffer: boolean;
  hasLoader: boolean;
}

export type OnboardingActions = typeof onboardingSlice.actions;

export const initialState: OnboardingState = {
  currentStep: {
    id: 'companyInfo2Step',
    stepName: 'Challenge Screen',
    isDefault: true,
  },
  isEUMember: false,
  trackingMode: 'mixed',
  inviteSuccessCount: 0,
  hasCompletedOnboarding: false,
  wasInitiated: false,
  showCcOffer: false,
  hasLoader: false,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateStep: (state, { payload }: PayloadAction<CurrentStep>) => {
      const isDefault = payload.isDefault ?? true;

      state.currentStep = { ...payload, isDefault };
    },
    setEUMember: (state, action: PayloadAction<boolean>) => {
      state.isEUMember = action.payload;
    },
    setTrackingMode: (state, action: PayloadAction<CompanyTrackingMode>) => {
      state.trackingMode = action.payload;
    },
    setInviteSuccessCount: (state, { payload }: PayloadAction<number>) => {
      state.inviteSuccessCount = payload;
    },
    setCompletedOnboarding: (state, { payload }: PayloadAction<boolean>) => {
      state.hasCompletedOnboarding = payload;
    },
    setShowCcOffer: (state, action: PayloadAction<boolean>) => {
      state.showCcOffer = action.payload;
    },
    setHasLoader: (state, action: PayloadAction<boolean>) => {
      state.hasLoader = action.payload;
    },
  },
});

export const selectOnboardingPageTitle = (state: RootState) => `${ selectCompanyName(state) } - Onboarding`;

export const {
  updateStep,
  setEUMember,
  setTrackingMode,
  setInviteSuccessCount,
  setCompletedOnboarding,
  setShowCcOffer,
  setHasLoader,
} = onboardingSlice.actions;

export const onboardingActions = onboardingSlice.actions;
export default onboardingSlice.reducer;
