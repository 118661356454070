import { DownloadAppsStepComponent } from '@/components/Steps/DownloadAppsStep/DownloadAppsStep';
import { withDownloadAppsStepGuard } from '@/components/Steps/DownloadAppsStep/DownloadAppsStepGuard';
import { DownloadAppsStep, StepInfo } from '@/types/steps.type';

export const DownloadAppsStepInfo: StepInfo<DownloadAppsStep> = {
  Component: withDownloadAppsStepGuard(DownloadAppsStepComponent),
  id: 'downloadAppsStep',
  stepName: 'Preferred TimeDoctor App Screen',
  title: 'onboarding:download_apps_step.title',
  desc: 'onboarding:download_apps_step.desc',
  Info: '',
};
