import { useFeature } from '@growthbook/growthbook-react';

import { DefaultFlowModel } from '@/features/default-flow/models/default-flow.model';
import { useTracking } from '@/hooks';
import { FlowModel } from '@/models/flow.model';
import { ExperimentProps } from '@/types/tracking.types';

export const useDownloadApps =  (flow: FlowModel = new DefaultFlowModel()) => {
  const { trackExperiment } = useTracking(flow);
  const { value: browserPromoExperiment } = useFeature<ExperimentProps>('promote-browser-app');

  const shouldTrackExperiment = Boolean(browserPromoExperiment?.variationid);
  const isVariationB = browserPromoExperiment?.variationid === 'B';
  const executeBrowserPromoTracking = async () => {
    if (isVariationB) {
      flow.addDownloadAppStep();
    }
    if (shouldTrackExperiment) {
      await trackExperiment(browserPromoExperiment as ExperimentProps);

    }
  };

  return {
    executeBrowserPromoTracking,
  };
};
