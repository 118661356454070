import MuiSnackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';


const DEFAULT_AUTO_HIDE_DURATION = 2500;


export const SnackbarComponent = styled(
  ({ autoHideDuration = DEFAULT_AUTO_HIDE_DURATION, ...restProps }: SnackbarProps) =>
    <MuiSnackbar { ...restProps } autoHideDuration={ autoHideDuration } />,
  {
    shouldForwardProp: (prop) => !!prop,
  },
)(({ theme }) => ({
  '& .MuiSnackbarContent-root': {
    backgroundColor: theme.palette.info.dark,
    borderRadius: '4px',
    padding: '14px 16px',
    boxShadow: '0 3px 5px -1px rgba(0, 0, 0, 0.20), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)',
    '& .MuiSnackbarContent-message': {
      ...theme.typography.body2,
      padding: 0,
      color: '#fff',
    },
    '& .MuiSnackbarContent-action': {
      marginRight: 0,
    },
  },
}));
