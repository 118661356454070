import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import {
  CheckoutCustomer,
  CheckoutOpenOptions,
  InitializePaddleOptions,
  PaddleEventData,
} from '@paddle/paddle-js/types';
import type { CheckoutEventNames } from '@paddle/paddle-js/types/checkout/events';

import { useCCOfferTracking, useOnboardingTask } from '@/hooks';
import { selectCompanyId, selectUserEmail } from '@/redux/features/authSlice';
import CompanyRequests from '@/requests/company.requests';
import DeviceAndGeoDetector from '@/utils/device-detector.utils';

type CheckoutConfig = Exclude<CheckoutOpenOptions, 'transactionId'> & { transactionId: string };

export const usePaddle = () => {
  const { redirectToOfferOrDashboard } = useOnboardingTask();
  const [paddle, setPaddle] = useState<Paddle>();
  const userEmail  = useSelector(selectUserEmail);
  const tdCompanyId  = useSelector(selectCompanyId);
  const {
    trackCompletedCheckout,
    trackFailedCheckout,
    trackDiscountRemoved,
  } = useCCOfferTracking();
  const prevCheckOutEventNameRef = useRef<CheckoutEventNames | undefined>(undefined);

  const renderInlineCheckout = useCallback(async (transactionId: string) => {
    const { country } = await DeviceAndGeoDetector.initialize();

    if (!userEmail && !tdCompanyId && !transactionId) {
      throw new Error('Checkout Error: Cannot get user and company');
    }

    const customer: CheckoutCustomer = {
      email: userEmail,
      address: {
        countryCode: country,
      },
    };

    const customData = {
      tdCompanyId,
      deploymentId: `${ process.env.NEXT_PUBLIC_DEPLOYMENT_ID }`,
    };

    const checkoutConfig: CheckoutConfig = {
      settings: {
        allowLogout: false,
        displayMode: 'inline',
        frameTarget: 'inline-checkout-container',
        frameStyle: 'position:relative; width: 100%; min-height: 100%',
        frameInitialHeight: 450,
        showAddDiscounts: false,
      },
      customer,
      customData,
      transactionId,
    };

    paddle?.Checkout.open(checkoutConfig);
  }, [paddle]);

  const handlePaddleEvent = useCallback(async (event: PaddleEventData) => {
    // Use useRef to hold the previous event name and to avoid multiple track calls
    if (prevCheckOutEventNameRef.current === event.name) return;
    prevCheckOutEventNameRef.current = event.name; // Update the ref after checking

    //default to 0 if no recurring total
    const selectedTotal = event.data?.items[0]?.recurring_totals?.total ?? 0;

    switch (event.name) {
      case 'checkout.completed': {
        tdCompanyId && await CompanyRequests.putCompanySettings(tdCompanyId, {
          custom: { completedStep: { addCreditCard: true }},
        });
        await trackCompletedCheckout(selectedTotal);
        redirectToOfferOrDashboard(true);
        break;
      }
      case 'checkout.payment.failed': {
        await trackFailedCheckout(selectedTotal);
        break;
      }
      case 'checkout.discount.removed': {
        await trackDiscountRemoved();
        break;
      }
    }
  }, [trackCompletedCheckout, trackFailedCheckout, trackDiscountRemoved, redirectToOfferOrDashboard]);

  const paddleSetup = {
    token: `${ process.env.NEXT_PUBLIC_PADDLE_CLIENT_TOKEN }`,
    eventCallback: handlePaddleEvent,
    environment: process.env.NEXT_PUBLIC_PADDLE_ENVIRONMENT,
  } as InitializePaddleOptions;

  useEffect(() => {
    initializePaddle(paddleSetup).then(
      (paddleInstance: Paddle | undefined) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }, []);

  return { renderInlineCheckout };
};
