import { FC, PropsWithChildren, ReactElement, ReactNode, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import MuiCloseIcon from '@mui/icons-material/Close';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiIconButton from '@mui/material/IconButton';
import { ModalProps } from '@mui/material/Modal';
import { TypographyTypeMap } from '@mui/material/Typography';

import { RenderIf } from '@/shared/RenderIf';

export type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeBtn'

type DialogProps = {
  children: {
    title?: ReactElement<TypographyTypeMap>;
    content: ReactNode;
  }
  onClose?: (e: Record<string, unknown>, reason: CloseReason) => void;
  showClose?: boolean;
} & Omit<MuiDialogProps, 'open' | 'onClose' | 'children'>

type DialogTitleProps = PropsWithChildren<{
  onCloseClick: () => void;
  showClose?: boolean;
}>

const styles = {
  root: css`
    & .MuiBackdrop-root {
      backdrop-filter: blur(10px);
    }

    & .MuiPaper-root {
      padding: var(--dialog-pd, 0 var(--space-24) var(--space-24));
      margin: var(--dialog-mg, var(--space-16));
      height: var(--dialog-height, calc(100% - 32px));
      max-height: var(--dialog-max-height, 650px);
      max-width: var(--dialog-max-width, 650px);
      border-radius: var(--dialog-border-radius, 4px);
      background: var(--dialog-background, #fff);
    }
  `,
  title: css`
    padding: var(--dialog-header-pd, var(--space-28) 0);
    position: relative;
  `,
  closeIcon: css`
    height: 32px;
    position: absolute;
    top: 0;
    right: var(--use-dialog-close-icon-right, 0);
    bottom: 0;
    margin: auto 0;
    padding: 4px;
  `,
  content: css`
    padding: 0;
    margin-right: calc(-1 * var(--dialog-gutter, var(--space-24)));
    padding-right: var(--dialog-gutter, var(--space-24));

    & * {
      text-align: left;
    }

    & li {
      margin-top: var(--space-16);
    }
  `,
};

const DialogTitle: FC<DialogTitleProps> = ({ children, onCloseClick, showClose = true }) => (
  <MuiDialogTitle css={ styles.title }>
    { children }
    <RenderIf condition={ showClose }><MuiIconButton
      aria-label="Dismiss special offer"
      onClick={ onCloseClick }
      css={ styles.closeIcon }
      className="Dialog-close-btn"
    >
      <MuiCloseIcon />
    </MuiIconButton></RenderIf>
  </MuiDialogTitle>
);

export const useDialog = () => {
  const [isDialogShown, setIsDialogShown] = useState(false);

  const showDialog = () => setIsDialogShown(true);
  const hideDialog = () => setIsDialogShown(false);

  const DialogComponent: FC<DialogProps> = useMemo(
    () =>
      function DialogComponent(
        {
          children,
          onClose,
          showClose,
          ...restProps
        },
      ) {
        const handleCloseDialog: ModalProps['onClose'] = (evt, reason) => {
          if (onClose) {
            onClose(evt, reason);
            return;
          }
          hideDialog();
        };

        const handleCloseClick = () => {
          if (onClose) {
            onClose({}, 'closeBtn');
            return;
          }

          hideDialog();
        };

        return (
          <MuiDialog
            css={ styles.root }
            open={ isDialogShown }
            onClose={ handleCloseDialog }
            className="td-dialog"
            { ...restProps }
          >
            <DialogTitle onCloseClick={ handleCloseClick } showClose={ showClose }>
              { children.title }
            </DialogTitle>
            <MuiDialogContent css={ styles.content }>
              <div className="content-wrapper">{ children.content }</div>
            </MuiDialogContent>
          </MuiDialog>
        );
      },
    [isDialogShown],
  );

  return {
    Dialog: DialogComponent,
    showDialog,
  };
};
