import { ComponentType, MouseEventHandler } from 'react';

import { useTracking } from '@/hooks';
import { TrackingEvent } from '@/types/tracking.types';

type WithTrackingWrapperProps = {
  /** Name of the event to be tracked. When this is provided, the button click will be tracked. */
  trackEvent?: TrackingEvent;

  /** Additional properties to be passed to the event tracker. */
  trackProps?: Record<string, unknown>;
}

type ComponentProps = {
  onClick?: MouseEventHandler | undefined;
} & Record<string, unknown>

export const withTracking = <P extends ComponentProps>(Component: ComponentType<P>) => (
  function WithTrackingWrapper(props: P & WithTrackingWrapperProps) {
    const { trackAppEvent } = useTracking();
    const { trackEvent, trackProps, onClick, ...restProps } = props;

    const handleClick: MouseEventHandler = (e) => {
      if (trackEvent) trackAppEvent(trackEvent, trackProps);
      if (onClick) onClick(e);
    };

    return (
      <Component
        { ...restProps as P }
        onClick={ handleClick }
      />
    );
  }
);
