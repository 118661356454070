import { Axios, AxiosError } from 'axios';

import { DEFAULT_TIMEOUT } from '../Axios';

const retryableErrorsMap = new Map([
  ['ETIMEDOUT', 408],
  ['ECONNABORTED', 499],
]);
const DEFAULT_RETRY_INC = 1.5;

/** Handle all errors gracefully */
export const rejectedResponseInterceptor = ({ code, config, message, response }: AxiosError, axiosInstance: Axios) => {
  // eslint-disable-next-line no-console
  console.warn('Axios request error', message);

  const isRetryableError = code && retryableErrorsMap.get(code);

  if (config && config.retry && isRetryableError) {
    config.retry -= 1;
    const retryTimeoutInc = config.retryTimeoutInc || DEFAULT_RETRY_INC;
    const initialTimeout = config.timeout || DEFAULT_TIMEOUT;

    return axiosInstance.request({
      ...config,
      timeout: initialTimeout * retryTimeoutInc,
    });
  }

  return response ?? {
    status: code
      ? retryableErrorsMap.get(code) ?? 499
      : 499,
  };
};
