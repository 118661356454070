import { useEffect, useState } from 'react';


type CopyToClipboardResult = {
  success: boolean;
}

export const useClipboardHook = () => {
  const [clipboard, setClipboard] = useState<Clipboard>();

  useEffect(() => {
    setClipboard(navigator.clipboard);
  }, []);

  const copyToClipboard = async (text: string): Promise<CopyToClipboardResult> => {
    if (!clipboard) return { success: false };

    try {
      await clipboard.writeText(text);

      return { success: true };
    } catch (error) {
      return { success: false };
    }
  };

  return {
    copyToClipboard,
  };
};
