import { CompanySettings } from '../types';
import { utilities } from '../utils/utilities';

import AuthRequests from './auth.requests';

class CompanyRequests {
  static putCompanySettings(companyId: string, settings: Partial<CompanySettings>) {
    return AuthRequests.axiosInstance.put(
      `/1.0/companies/${ companyId }`,
      utilities.flatObj(settings),
    );
  }
}

export default CompanyRequests;

