import { FC, useState } from 'react';
import { PhoneInputProps } from 'react-international-phone';
import styled from '@emotion/styled';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import { PhoneNumberUtil } from 'google-libphonenumber';
import useTranslation from 'next-translate/useTranslation';

import { PhoneNumberInput } from '@/components/Steps/PhoneNumberStep/PhoneNumberInput';
import { useCountryCode, useOnboardingTask, useTracking, useTraits } from '@/hooks';
import { FlexBox } from '@/shared/FlexBox';
import { StepCommonProps } from '@/types/steps.type';


type PhoneState = {
  phone: string;
  countryCode: string | undefined;
}

export const PhoneNumberStepComponent: FC<StepCommonProps> = ({ flow }) => {
  const { t } = useTranslation();
  const { countryCode } = useCountryCode();
  const { goToNextStep } = useOnboardingTask(flow);
  const { trackAppEvent } = useTracking(flow);
  const { updateTraits } = useTraits();

  const [formState, setFormState] = useState<'idle' | 'loading' | 'error'>('idle');
  const [error, setError] = useState<string | undefined>();
  const [phoneState, setPhoneState] = useState<PhoneState>({
    phone: '',
    countryCode: countryCode || 'us',
  });

  async function handleCompleteSetup() {
    setFormState('loading');

    if (!isPhoneValid(phoneState.phone)) {
      handleInvalidPhone();
      return;
    }

    updateTraits({
      companyTraits: {
        onboarding_step: 'Challenge Screen',
      },
      userTraits: { phonenumber: phoneState.phone },
      sendSegment: true,
    });
    trackAppEvent('Added Phone Number On Onboarding', {
      phonenumber: phoneState.phone,
      countrycode: phoneState.countryCode,
    });
    await goToNextStep();
  }

  function handleInvalidPhone() {
    setFormState('error');
    setError(t('onboarding:phone_number_step.errors.invalid_phone'));
    trackAppEvent('Unsuccessful Phone Number Submission', {
      phonenumber: phoneState.phone,
      error: 'Invalid Phone Number',
    });
  }

  const handlePhoneChange: PhoneInputProps['onChange'] = (phone, { country }) => {
    if (error) setError(undefined);

    setPhoneState({
      phone,
      countryCode: country.iso2,
    });
  };

  return (
    <FlexBox flexDirection="column" alignItems="center" rowGap={ 7 }>
      <PhoneInputWrapper>
        <PhoneNumberInput
          renderIf={ !!countryCode }
          phone={ phoneState.phone }
          onChange={ handlePhoneChange }
          countryCode={ countryCode }
          error={ error }
        />
      </PhoneInputWrapper>

      <MuiLoadingButton
        variant="contained"
        loading={ formState === 'loading' }
        onClick={ handleCompleteSetup }
        aria-label="Complete Setup"
      >
        { t('onboarding:phone_number_step.submit_button') }
      </MuiLoadingButton>
    </FlexBox>
  );
};


const PhoneInputWrapper = styled.div`
  margin-top: 20px;
  height: 36px;
`;

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
