import React, { ChangeEventHandler, MouseEventHandler, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import MuiEmailIcon from '@mui/icons-material/Email';
import MuiPersonIcon from '@mui/icons-material/Person';
import MuiFormControl from '@mui/material/FormControl';
import MuiFormHelperText from '@mui/material/FormHelperText';
import MuiInput from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import MuiInputLabel from '@mui/material/InputLabel';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import styles from '@/components/InviteMember/InviteMember.module.scss';
import { InviteStepCtx } from '@/components/Steps';
import { AccessLevelPicker } from '@/components/Steps/InviteTeamStep/AccessLevelPicker';
import { FlexBox } from '@/shared/FlexBox';
import { TextField } from '@/shared/TextField';

import { AccessLevel, Member } from '../../types';

type MemberFieldVariantProps = {
  accessLvl: AccessLevel;
  inputId: string;
  onAccessLvlChange: (accessLvl: AccessLevel) => void;
  onInputClick: MouseEventHandler<HTMLInputElement>;
  onInputChange: ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
};

export type MemberFieldProps = {
  inputId: string;
  member: Member;
  variant: 'legacy' | 'exp';
  onAccessLvlChange: (accessLvl: AccessLevel) => void;
  onInputClick?: InviteMemberTypes.InputClickFn;
  onInputChange?: InviteMemberTypes.InputChangeFn;
  required?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const EMAIL_PATTERN_REGEX = /^[^@\s]+@([-\w]+\.)+[-\w]{2,4}$/g;

const muiFormControlStyles = {
  root: css`
    & .MuiInputLabel-root {
      transform: translate(0, 10px) scale(1);
    }

    & .MuiInput-root::after, & .MuiInput-root:hover::before {
      border-bottom: 0 !important;
    }
  `,
};


export const MemberField: React.FC<MemberFieldProps> = (
  {
    inputId,
    member,
    onInputClick = () => ({}),
    onInputChange = () => ({}),
    onAccessLvlChange,
    variant,
    ...restProps
  },
) => {
  const { getValues, setValue } = useFormContext();

  const handleInputClick = () =>
    onInputClick({ email: getValues(inputId), role: member.role });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setValue(inputId, target.value, { shouldDirty: true, shouldValidate: true });

    onInputChange(inputId, { email: target.value, role: member.role });
  };

  return variant === 'legacy' ? (
    <LegacyMemberField
      inputId={ inputId }
      required={ restProps.required }
      accessLvl={ member.role }
      onInputClick={ handleInputClick }
      onInputChange={ handleInputChange }
      onAccessLvlChange={ onAccessLvlChange }
    />
  ) : (
    <DefaultMemberField
      inputId={ inputId }
      required={ restProps.required }
      accessLvl={ member.role }
      onInputClick={ handleInputClick }
      onInputChange={ handleInputChange }
      onAccessLvlChange={ onAccessLvlChange }
    />
  );

};

export namespace InviteMemberTypes {
  export type InputChangeFn = (inputId: string, member: Member) => void
  export type InputClickFn = (member: Member) => void
}

const LegacyMemberField: React.FC<MemberFieldVariantProps> = ({
  inputId,
  required,
  accessLvl,
  onInputClick = () => ({}),
  onInputChange = () => ({}),
  onAccessLvlChange,
}) => {
  const { control, formState: { errors }} = useFormContext();

  return (
    <div className={ classNames(styles.InviteMember, 'd-flex') }>
      <div className={ styles.InviteMember__InputArea }>
        <div className={ classNames(styles.InviteMember__InputArea__BorderWrap, 'd-flex') }>
          <MuiPersonIcon color="info" />
          <MuiFormControl
            variant="standard"
            css={ muiFormControlStyles.root }
            error={ errors[inputId]?.type === 'async' || errors[inputId]?.type === 'pattern' }
            fullWidth>
            <MuiInputLabel htmlFor={ inputId }>Email address</MuiInputLabel>
            <Controller
              name={ inputId }
              control={ control }
              rules={{ required, pattern: EMAIL_PATTERN_REGEX }}
              defaultValue=""
              render={ ({ field }) => (
                <MuiInput
                  { ...field }
                  id={ inputId }
                  type="email"
                  onClick={ onInputClick }
                  onChange={ onInputChange }
                />) }
            />
          </MuiFormControl>
        </div>
        { errors[inputId]?.type === 'async' &&
          <MuiFormHelperText error sx={{ fontSize: '10px', padding: '0 12px' }}>
            { errors[inputId]?.message as string }
          </MuiFormHelperText>
        }
        { errors[inputId]?.type === 'pattern' &&
          <MuiFormHelperText error sx={{ fontSize: '10px', padding: '0 12px' }}>
            Please provide a valid email
          </MuiFormHelperText>
        }
      </div>
      <div className={ styles.InviteMember__AccessLvl }>
        <p
          className={ classNames(styles.InviteMember__AccessLvl__Title, 'no-mg ft-size-12 color-gray-primary') }
        >
          Access level
        </p>
        <AccessLevelPicker
          accessLvlValue={ accessLvl }
          variant="buttons"
          onAccessLvlChange={ onAccessLvlChange }
        />
      </div>
    </div>
  );
};

const DefaultMemberField: React.FC<MemberFieldVariantProps> = ({
  inputId,
  accessLvl,
  onInputClick = () => ({}),
  onInputChange = () => ({}),
  onAccessLvlChange,
}) => {
  const { t } = useTranslation();
  const { control, formState: { errors, touchedFields }} = useFormContext();
  const isTouched = touchedFields[inputId];
  const { didCopyLink } = useContext(InviteStepCtx);

  return (
    <FlexBox gap={ 1.5 } flexWrap="wrap">
      <FlexBox flexDirection="column" flex="1 1 auto">
        <Controller
          name={ inputId }
          control={ control }
          defaultValue=""
          rules={{ required: !didCopyLink, pattern: EMAIL_PATTERN_REGEX }}
          render={ ({ field }) => (
            <TextField
              { ...field }
              error={ errors[inputId]?.type === 'async' || (errors[inputId]?.type === 'pattern' && isTouched) }
              id={ inputId }
              type="email"
              variant="filled"
              onClick={ onInputClick }
              onChange={ onInputChange }
              label={ t('onboarding:invite_members_step.email_address') }
              InputProps={{
                startAdornment: <InputAdornment position="start"><MuiEmailIcon color="info" /> </InputAdornment>,
              }}
            />
          ) }
        />

        { errors[inputId]?.type === 'async' &&
          <MuiFormHelperText error sx={{ fontSize: '10px', padding: '0 12px' }}>
            { errors[inputId]?.message as string }
          </MuiFormHelperText>
        }
        { errors[inputId]?.type === 'pattern' && isTouched &&
          <MuiFormHelperText error sx={{ fontSize: '10px', padding: '0 12px' }}>
            Please provide a valid email
          </MuiFormHelperText>
        }
      </FlexBox>
      <AccessLevelPicker
        variant="select"
        accessLvlValue={ accessLvl }
        onAccessLvlChange={ onAccessLvlChange }
      />
    </FlexBox>
  );
};


