import { Typography } from '@mui/material';
import createMuiTheme from '@mui/material/styles/createTheme';

import { breakPtDown } from '../../../../emotion.definitions';
import { utils } from '../../../theme/utils.theme';

export const theme = createMuiTheme({
  typography: {
    h4: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '32px',
      margin: 0,
      textAlign: 'left',
      [breakPtDown['sm']]: {
        fontSize: '1.5rem',
        lineHeight: '26px',
      },
    },
    h5: {
      fontSize: '1.15rem',
      fontWeight: 700,
      lineHeight: 1,
      margin: '18px 0',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: '0.44px',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.14px',
    },
  },
  components: {
    MuiDialogTitle: {
      defaultProps: {
        component: 'header',
      },
    },
  },
  utils,
});

const TrackingSilentInteractiveContent = () => (
  <>
    <Typography variant="body1">
      The Time Doctor desktop apps track activities on the computers that they’re installed on and send that
      information
      back to Time Doctor’s servers where it will appear in your reports on the website.

      Here are the differences between the two desktop apps that we have available.
    </Typography>
    <Typography variant="h5">The Silent App</Typography>
    <Typography variant="h6">How It Works</Typography>
    <ul>
      <li>
        The silent app will track computer activities whenever the computer is on.
      </li>
      <li>
        Since the silent app has no user interface, users won’t interact with it and won’t see it running unless they
        look
        at all running programs in their Task Manager (Windows).
      </li>
    </ul>
    <Typography variant="h6">How to Install It</Typography>
    <ul>
      <li>
        You can install it manually on any single computer by running the installation file on that computer.
      </li>
      <li>
        Since the installation file is an MSI file, a system administrator can install the silent app throughout a
        network
        of computers through Group Policy.
      </li>
      <li>
        It’s not possible to invite people to use the silent app.
      </li>
    </ul>
    <Typography variant="h6">Additional Info</Typography>
    <ul>
      <li>
        The app that you download from your <a target="_blank" href="/download">Downloads</a> page contains information
        that identifies
        it as being from your
        company’s account. Any computer that the app is installed on will send data to your company’s account.
      </li>
    </ul>

    <Typography variant="h5">The Interactive App</Typography>
    <Typography variant="h6">How It Works</Typography>
    <ul>
      <li>
        The interactive app has a user interface that allows the user to start or stop tracking their activities
        whenever they choose.
      </li>
      <li>
        It tracks all the same computer activity info that the silent app tracks, unless the user has paused the
        tracking.
      </li>
    </ul>
    <Typography variant="h6">Tasks vs. No Tasks</Typography>
    <ul>
      <li>
        If you’re using the “Projects & Tasks” feature (which admins can activate on the <b>Edit Company</b> page), the
        interactive desktop app will display a window containing a list of projects & tasks. The user can click on a
        project & task to track time to it.
      </li>
      <li>
        If you’re not using the “Projects & Tasks” feature, the desktop interface will simply be an icon in the system
        tray (Windows & Linux) or in the menu bar (Mac). There will be a menu attached to it that allows the user to
        start or stop tracking and change some settings.
      </li>
    </ul>
    <Typography variant="h6">How to Install It</Typography>
    <ul>
      <li>
        Each user installs their own interactive app. Admins can send invitations to users from the <b>Invite
        Users</b> page. The invitations will include a link for the invited user to download and install the app.
      </li>
    </ul>
    <Typography variant="h6">Your entire company can be:</Typography>
    <ul>
      <li>
        <b>Silent</b><br />
        Users can only track work with the silent app. This option is suitable when your users are in an office where
        their computers are used exclusively for work, and you don’t want the app to take up any of their time or focus.
      </li>
      <li>
        <b>Interactive</b><br />
        Users can only track work with the interactive app. This option is more suitable when your users work remotely
        and/or are allowed to use their computers for personal activities.
      </li>
      <li>
        <b>Mixed</b><br />
        Both silent and interactive apps can be used.
      </li>
    </ul>

    <Typography variant="body1">
      Admins and owners can control this setting for their company on the <b>Edit Company</b> page.
    </Typography>
  </>
);

export default TrackingSilentInteractiveContent;
