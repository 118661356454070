import cookieUtils from './cookies.util';

const flatObj = (obj: Record<string, unknown>, prefix = '') => {
  const objKeys = Object.keys(obj);
  let result = {};

  for (const objKey of objKeys) {
    const currentVal = obj[objKey];

    if (currentVal && isRecord(currentVal)) {
      result = {
        ...result,
        ...flatObj(currentVal, `${ prefix }${ objKey }.`),
      };
      continue;
    }

    const key = `${ prefix }${ objKey }`;
    result = {
      ...result,
      [key]: currentVal,
    };
  }

  return result;
};

const isRecord = (obj: unknown): obj is Record<string, unknown> =>
  !!(obj && typeof obj === 'object' && Object.keys(obj).length);


const getDataLayer = (prop: string) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return null;

  for (const item of dataLayer) {
    if (item[prop]) {
      return decodeURI(item[prop]) || null;
    }
  }

  return null;
};

function uuid() {
  let uuid = cookieUtils.getCookie('td_anonymousId');
  if (!uuid) {
    let dateMs = new Date().getTime();

    uuid = 'TD-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (strToReplace) {
        const randomNum = (dateMs + Math.random() * 16) % 16 | 0;
        dateMs = Math.floor(dateMs / 16);
        return (strToReplace == 'x' ? randomNum : (randomNum & 0x3) | 0x8).toString(16);
      },
    );
    cookieUtils.setCookie('td_anonymousId', uuid);
  }
  return uuid;
}


function segmentAnonymousId() {
  const stdId = cookieUtils.getCookie('ajs_anonymous_id');
  return stdId
    ? stdId.replace(/['"]+/g, '')
    : '';
}

function filterObjProps(obj: Record<string, unknown> | undefined) {
  const resultObj = { ...obj };

  for (const key in resultObj) {
    if (Object.prototype.hasOwnProperty.call(resultObj, key)) {
      if (resultObj[key] == undefined) delete resultObj[key];
    }
  }

  return resultObj;
}

function delayedFn(callback: () => void, delay = 0) {
  setTimeout(callback, delay);
}

export const utilities = {
  flatObj,
  isRecord,
  getDataLayer,
  segmentAnonymousId,
  uuid,
  filterObjProps,
  delayedFn,
};
