import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFeature, useGrowthBook } from '@growthbook/growthbook-react';
import Router, { useRouter } from 'next/router';

import { DefaultFlowModel } from '@/features/default-flow/models/default-flow.model';
import { FlowModel } from '@/models/flow.model';
import { selectCompanyId } from '@/redux/features/authSlice';
import { setCompletedOnboarding, updateStep } from '@/redux/features/onboardingSlice';

import { Env } from '../../../environments';
import CompanyRequests from '../../requests/company.requests';
import { CompanyTrackingMode, OnboardingStepsMap } from '../../types';
import tokenStorageService from '../../utils/token.util';
import { useAppDispatch, useAppSelector } from '../storeHooks';
import { useTracking } from '../useTracking';
import { useTraits } from '../useTraits';

import { useAccountCreated } from './useAccountCreated';

type NextStepArgs = {
  skipNext?: boolean;
  withNextStepId?: keyof OnboardingStepsMap;
  stepData?: Record<string, unknown>;
}

export const useOnboardingTask = (flow: FlowModel = new DefaultFlowModel()) => {
  const dispatch = useAppDispatch();

  const { inviteSuccessCount, currentStep, showCcOffer } = useAppSelector(state => state.onboarding);
  const { updateTraits } = useTraits();
  const { logTrackingCall, trackAppEvent, trackOnboardingStep } = useTracking(flow);
  const { onAccountCreated } = useAccountCreated(flow);
  const companyId = useSelector(selectCompanyId);
  const router = useRouter();
  const showCcOfferFeature = useFeature('one-dollar-cc-offer');
  const growthBook = useGrowthBook();

  function getNextStep() {
    const currentStepIndex = flow.getSteps().findIndex(step => step.id === currentStep.id);
    return flow.getSteps()[currentStepIndex + 1];
  }

  /**
   * Redirects to the home of the app-web.
   * When in development mode, this will redirect to `/` in order to make the dev experience better.
   */
  const redirectToOfferOrDashboard = useCallback(async (shouldSkipOffer = false) => {
    if (showCcOffer && showCcOfferFeature.on && !shouldSkipOffer) {
      if (router.pathname !== '/offer') Router.push('/offer');
      return;
    }

    // Do not redirect to app-web in Dev mode in order to make dev experience better.
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === Env.Development) return;

    const token = tokenStorageService.getToken();

    Router.push(`${ process.env.NEXT_PUBLIC_APP_WEB_URL }?token=${ token }`);
  }, [showCcOffer, showCcOfferFeature]);


  const isRecordOfStrings = (value: unknown): value is Record<string, string> =>
    typeof value === 'object' &&
    value !== null &&
    Object.values(value).every((item) => typeof item === 'string');


  const buildLoginUrl = (queryParams?: string): string => {
    const baseUrl = process.env.NEXT_PUBLIC_APP_WEB_URL;
    const queryString = queryParams ? `?${ queryParams }` : '';
    return `${ baseUrl }/login${ queryString }`;
  };

  /**
   * Redirects to the app-web login page.
   */
  const redirectToLogin = useCallback(() => {
    const { query } = Router;
    let currentQueryParams = '';

    // Ensure query is of type Record<string, string> (an object with string properties)
    if (isRecordOfStrings(query)) {
      const queryParams = new URLSearchParams(query);
      currentQueryParams = queryParams.toString();
    }

    const loginUrl = buildLoginUrl(currentQueryParams);

    Router.push(loginUrl);
  }, []);


  /**
   * Makes the needed track calls when the onboarding is completed.
   */
  const completeOnboardingTracking = useCallback(async () => {
    await trackAppEvent(
      'Onboarding Step',
      { onboardingstep: flow.onboardingCompleteStep, trackingtype: flow.trackingMode },
    );
    await trackOnboardingStep(flow.onboardingCompleteEvent);

    logTrackingCall('Onboard Complete', 'event');

    if (typeof window?.analytics?.user === 'function') {
      window.analytics.user().traits({});
    }
  }, [flow, trackOnboardingStep, trackAppEvent, logTrackingCall]);

  /**
   * Performs different tasks that are required when the onboarding is completed. That includes:
   * update of the global state, traits update, track calls and redirection to the dashboard.
   */
  const onCompletedOnboarding = useCallback(
    async (optionalParams?: { localTrackingMode?: CompanyTrackingMode, invitedCount?: number }) => {
      if (growthBook) growthBook.setAttributes({ ...growthBook.getAttributes(), onboarding_step: 'none' });
      dispatch(setCompletedOnboarding(true));

      const invitedCount = optionalParams?.invitedCount || inviteSuccessCount;
      try {
        await updateTraits({
          companyTraits: {
            invitePopupSelection: invitedCount,
            onboarding_step: flow.onboardingCompleteStep,
          },
          userTraits: { workspace_tracking_type: flow.trackingMode },
          sendSegment: true,
        });

        await completeOnboardingTracking();
        await CompanyRequests.putCompanySettings(companyId, { custom: { completedOnBoarding: true }});
      } finally {
        redirectToOfferOrDashboard();
      }
    },
    [
      flow,
      inviteSuccessCount,
      updateTraits,
      redirectToOfferOrDashboard,
      companyId,
      dispatch,
      completeOnboardingTracking,
    ],
  );

  /**
   * Updates the global state with the appropriate step. If the next step does not exist, the
   * onboarding completion is executed.
   *
   * @param withNextStepIndex overrides the normal flow of the steps and moves the step state to the
   *  given index.
   */
  const goToNextStep = useCallback(({ withNextStepId, stepData, skipNext }: NextStepArgs = {}) => {
    const skipAddition = skipNext ? 1 : 0;
    const withNextStepIdIndex = withNextStepId
      ? flow.getSteps().findIndex(step => step.id === withNextStepId)
      : undefined;
    const currentStepIndex = flow.getSteps().findIndex(step => step.stepName === currentStep.stepName);
    const nextStepIndex = withNextStepIdIndex ?? currentStepIndex + 1 + skipAddition;
    const nextStepInfo = flow.getSteps()[nextStepIndex];

    if (!nextStepInfo) return onCompletedOnboarding(stepData);

    dispatch(updateStep({ ...nextStepInfo, isDefault: false }));
  }, [currentStep, dispatch, flow, onCompletedOnboarding]);

  return {
    onAccountCreated,
    onCompletedOnboarding,
    goToNextStep,
    getNextStep,
    redirectToOfferOrDashboard,
    redirectToLogin,
  };
};

