import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';

export const TextFieldComponent = styled(MuiTextField, {
  shouldForwardProp: (prop) => !!prop,
})(({ theme, size, variant }) => ({
  ...(variant === 'filled' && {
    '&:hover': {
      '& .MuiFilledInput-root': {
        '&:before': {
          borderBottom: `2px solid ${ theme.palette.info.main }`,
        },
      },
    },

    '& .MuiFilledInput-root:not(:has(.MuiSelect-select))': {
      paddingLeft: '16px',
    },

    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.neutral.light,
      color: theme.palette.info.dark,

      '& .MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: 0,
      },
    },
    '& .MuiInputLabel-root:not(.Mui-focused, .Mui-error)': {
      color: theme.palette.neutral.main,
    },
    '& .MuiInputLabel-root:has(+ .MuiInputBase-adornedStart)': {
      transform: 'translate(48px, 16px) scale(1)',
      cursor: 'text',

      '&.Mui-focused, &.MuiFormLabel-filled': {
        transform: 'translate(48px, 8px) scale(0.75)',
        cursor: 'auto',
      },
    },
  }),

  ...(size === 'small' && {
    alignSelf: 'stretch',

    '& .MuiInputLabel-root': {
      display: 'none',
    },

    '& .MuiInputBase-root': {
      padding: '0 8px',
      flexGrow: 1,

      '& .MuiInputAdornment-root': {
        marginTop: '0 !important',
      },
    },

    '& .MuiInputBase-input': {
      padding: 0,
      height: '32px',

      ...theme.typography.subtitle1,
    },
  }),
}));
