import MuiDivider from '@mui/material/Divider';
import { CompoundAllowedColors, styled } from '@mui/material/styles';

type DividerProps = {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  lineColor?: CompoundAllowedColors;
}

export const DividerComponent = styled(MuiDivider)<DividerProps>(
  ({ theme, mb, mt, mr, ml, lineColor = 'neutral.light' }) => ({
    marginTop: theme.utils.spacing(mt),
    marginBottom: theme.utils.spacing(mb),
    marginLeft: theme.utils.spacing(ml),
    marginRight: theme.utils.spacing(mr),

    '&.MuiDivider-root::before, &.MuiDivider-root::after': {
      top: 0,
      bottom: 0,
      margin: 'auto 0',
      transform: 'none',
      borderColor: theme.utils.getPaletteColor(theme, lineColor),
    },
  }),
);
