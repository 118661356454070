import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { injectAxios } from '@/lib/axios/Axios';

import { FreeEmailDomainResponse } from '../../pages/api/free-email-domain.page';
import AuthRequests from '../../requests/auth.requests';
import { AuthorizationResponse, AuthState, Company } from '../../types';

type State = {
  auth: AuthState;
}

const initialState: AuthState = {
  token: undefined,
  company: undefined,
  user: undefined,
  loading: 'idle',
  isAdminLogin: false,
  hasFreeEmailDomain: false,
};

const authorization = createAsyncThunk<AxiosResponse<AuthorizationResponse, unknown>, string, {
  state: { auth: AuthState },
}>(
  'authorization',
  async (token, { rejectWithValue }) => {
    const response = await AuthRequests.authorization(token);

    if (response.status >= 400) return rejectWithValue(response);

    return response;
  },
  {
    condition: (arg, { getState }) => {
      const { auth } = getState();
      if (auth.loading === 'pending') return false;
    },
  },
);

const freeEmailDomain = createAsyncThunk<boolean, void, {
  state: { auth: AuthState },
}>(
  'freeEmailDomain',
  async (_, { getState }) => {
    const email = getState().auth.user?.email || '';
    const axios = injectAxios('/api');
    const response = await axios.get<FreeEmailDomainResponse>(`/free-email-domain?email=${ email }`);

    if (response.data.status === 'error') return false;

    return response.data.isFreeEmailDomain;
  },
);

const getOwnCompany = (companies: Company[] | undefined | null): Company | undefined =>
  companies?.find(company => company.role === 'owner');

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setLoading: (state, action: PayloadAction<'idle' | 'pending'>) => {
      state.loading = action.payload;
    },
    setAdminLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.isAdminLogin = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authorization.pending, state => {
        state.loading = 'pending';
      })
      .addCase(authorization.fulfilled, (state, { payload }) => {
        state.company = getOwnCompany(payload?.data?.data?.companies);
        state.user = payload?.data?.data;
        state.loading = 'idle';
      })
      .addCase(freeEmailDomain.fulfilled, (state, { payload }) => {
        state.hasFreeEmailDomain = payload;
      });
  },
});

export const selectUserId = ({ auth }: State) => auth.user?.id || '';
export const selectUserEmail = ({ auth }: State) => auth.user?.email || '';
export const selectCompanyId = ({ auth }: State) => auth.company?.id || '';
export const selectCompanyPlan = ({ auth }: State) => auth.company?.pricingPlan || '';
export const selectCompanyBasePlan = ({ auth }: State): string =>
  auth.company?.pricingPlan?.split('_')[0] || '';

export const selectCompanyStatus = ({ auth: { company }}: State) => company?.subscription?.status === 'free'
  ? 'trial expired'
  : company?.subscription?.status;

export const selectCompanyAccessLvl = ({ auth: { company }}: State) => company?.role === 'user'
  ? 'regular user'
  : company?.role;

export const selectCompanyName = (state: State) => {
  const { auth: { company, user, hasFreeEmailDomain }} = state;
  const getExpByName = getExpFn(state);
  const userEmail = user?.email || '';
  let companyName = getExpByName('companyname') as string;

  if (!hasFreeEmailDomain && company) {
    companyName = companyName || userEmail?.substring(userEmail?.lastIndexOf('@') + 1).split('.')[0];
  }

  return companyName.trim() || company?.name;
};

export const selectSubscriptionAmountByPlan = ({ auth: { company }}: State) => {
  const plan = company?.pricingPlan;
  const plans = {
    basic: '7.00',
    standard_new: '10.00',
    premium: '20.00',
    business: '12.00',
    enterprise: '24.00',
  };

  function isKeyOfPlans(key: unknown): key is (keyof typeof plans) {
    return (typeof key === 'string' && key in plans);
  }

  return (isKeyOfPlans(plan)) ? plans[plan] : '10.00';
};

export const getExpFn = ({ auth: { company }}: State) => (expName: string): unknown => {
  const experiments = company?.splitTest || [];
  const experimentFound = experiments.find(exp => exp.name === expName);

  return experimentFound?.value || '';
};


export const authThunk = {
  authorization,
  freeEmailDomain,
};
export const { setAdminLogin } = authSlice.actions;
export default authSlice.reducer;
