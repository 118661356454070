import * as Sentry from '@sentry/nextjs';
import { CaptureContext,Extras } from '@sentry/types';
import { AxiosResponse } from 'axios';

type SentryError = {
  message: string;
  response: AxiosResponse;
}

const isSentryError = (error: unknown): error is SentryError => 
  !!(error as SentryError)?.message && !!(error as SentryError)?.response;

class SentryService {

  private async withRetryAfter(action: () => Promise<void>): Promise<void> {
    try {
      await action();
    } catch (error: unknown) {
      if (isSentryError(error) && error.message.includes('429')) {
        const retryAfterHeader = error?.response?.headers?.['retry-after'];
        if (retryAfterHeader) {
          const waitTime = parseInt(retryAfterHeader) * 1000;
          await new Promise(resolve => setTimeout(resolve, waitTime));
          await this.withRetryAfter(action);
        }
      }
    }
  }

  async captureExceptionWithRetryAfter(exception: unknown, extra?: Extras): Promise<void> {
    await this.withRetryAfter(async () => {
      Sentry.captureException(exception, { extra });
    });
  }

  async captureMessageWithRetryAfter(message: string, options?: CaptureContext): Promise<void> {
    await this.withRetryAfter(async () => {
      await new Promise<void>((resolve) => {
        Sentry.captureMessage(message, options);
        resolve();
      });
    });
  }

}

export default new SentryService();
