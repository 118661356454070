import { AxiosResponse } from 'axios';

import { injectAxios } from '@/lib/axios/Axios';
import SentryService from '@/utils/sentry-service.util';

import { TrackEventApiPayload, TrackPageApiPayload } from '../types';

const EXCLUDED_EVENTS = ['Account Created', 'Onboard Complete'];

class TrackingRequests {
  private static _axiosInstance = injectAxios(`${ process.env.NEXT_PUBLIC_DATA_INTEGRITY_URL }`);

  static async postTracking(payload: TrackEventApiPayload | TrackPageApiPayload): Promise<AxiosResponse | void> {
    if (isTrackEventPayload(payload) && EXCLUDED_EVENTS.includes(payload.event)) return Promise.resolve();

    const apiKey = process.env.NEXT_PUBLIC_DATA_INTEGRITY_KEY || '';

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    };

    try {
      await this._axiosInstance.post('/api/v1/tracking', payload, {
        headers,
        params: {
          type: payload.type,
        },
      });

      return this.logTracking(payload);

    } catch (error) {
      // Log errors to Sentry
      const trackingErrorMessage = this.getTrackingMessage(payload);
      SentryService.captureExceptionWithRetryAfter(error, {
        extra: {
          errorContext: trackingErrorMessage,
          payload: payload,
        },
      });
    }
  }

  static getTrackingMessage(payload: Record<string, unknown>) {
    const trackingType = payload?.event ? 'Page' : 'Event';
    const trackingName = payload?.event || payload?.name || '';
    return `${ trackingType }: ${ trackingName }  UserId: ${ payload?.userId }`;
  }

  static logTracking(_payload: Record<string, unknown>) {
    // Log the traits being updated
    const trackingMessage = this.getTrackingMessage(_payload);
    SentryService.captureMessageWithRetryAfter(trackingMessage, {
      level: 'info',
      extra: {
        payload: _payload,
      },
    });

    return Promise.resolve();
  }
}

function isTrackEventPayload(payload: TrackEventApiPayload | TrackPageApiPayload): payload is TrackEventApiPayload {
  return (payload as TrackEventApiPayload)?.event != undefined;
}

export default TrackingRequests;


