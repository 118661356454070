import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useTracking } from '@/hooks';
import { CcOfferScreensType } from '@/types/billing.type';
import { OnboardingEvent } from '@/types/tracking.types';

import { selectSubscriptionAmountByPlan } from '../../redux/features/authSlice';

export type DismissedLocationType = CcOfferScreensType | 'noThanks';

type LocationMapping = {
   props: Record<string, string | number | boolean>
    eventName: OnboardingEvent;
};

type LocationMappings = {
    [key: string]: LocationMapping;
};

export const useCCOfferTracking = () => {
  const { trackOnboardingStep } = useTracking();
  const amountByPlan = useSelector(selectSubscriptionAmountByPlan);

  const trackOfferAccepted = useCallback(() => trackOnboardingStep('CreditCard Offer Accepted', {
    currency: 'USD',
    value: amountByPlan,
  }), [trackOnboardingStep, amountByPlan]);

  
  /**
 * Tracks the user's dismissal from different locations of the screen.
 * 
 * @param location - The location from which the offer was dismissed,
 * which could be from closing the offer directly, closing the billing interface, 
 * or rejecting the credit card offer.
 */
  const trackOfferDismissed = useCallback((location: DismissedLocationType) => {
    // Define mapping from location to props and event names for better readability and maintenance
    const locationMappings: LocationMappings = {
      'offer-details': { 
        props: { location: 'special offer popup' }, 
        eventName: 'Clicked X Button',
      },
      'checkout': { 
        props: { location: 'special offer billing popup' }, 
        eventName: 'Clicked X Button',
      },
      'noThanks': { 
        props: {}, 
        eventName: 'CreditCard Offer Rejected',
      },
    };

    // Do not proceed the tracking if the given location is not known
    if (!locationMappings[location]) return;

    const { props, eventName } = locationMappings[location];

    // Track the dismissed event with corresponding props
    return trackOnboardingStep(eventName, props);
  }, [trackOnboardingStep]);


  const trackCompletedCheckout = useCallback((value: number) => {
    const props = { value };
    const eventName = 'Credit Card Added On Special Offer';
    return trackOnboardingStep(eventName, props);
  }, [trackOnboardingStep]);

  const trackFailedCheckout = useCallback((value: number) => {
    const props = { value };
    const eventName = 'Unsuccessful Credit Card Submission on Special Offer';
    return trackOnboardingStep(eventName, props);
  }, [trackOnboardingStep]);

  const trackDiscountRemoved = useCallback(() => {
    const eventName = 'Removed Discount On Special Offer';
    return trackOnboardingStep(eventName);
  }, [trackOnboardingStep]);

  return {
    trackOfferAccepted,
    trackOfferDismissed,
    trackCompletedCheckout,
    trackFailedCheckout,
    trackDiscountRemoved,
  };
};
