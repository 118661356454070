import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { DefaultFlowModel } from '@/features/default-flow/models/default-flow.model';
import { FlowModel } from '@/models/flow.model';
import {
  getExpFn,
  selectCompanyAccessLvl,
  selectCompanyId,
  selectCompanyName,
  selectCompanyStatus,
} from '@/redux/features/authSlice';
import { utilities } from '@/utils/utilities';

import TrackingRequests from '../../requests/tracking.requests';
import { injectTrackingService } from '../../services/tracking';
import {
  CompanyTrackingMode,
  OnboardingEvent,
  Pages,
  SegmentOptions,
  StepNames,
  TrackExperimentProps,
  TrackingEvent,
} from '../../types';
import cookieUtils from '../../utils/cookies.util';
import DeviceAndGeoDetector from '../../utils/device-detector.utils';
import { useAppSelector } from '../storeHooks';
import { useTraits } from '../useTraits';

type TrackProps = {
  category?: string;
} & Record<string, unknown>

type StepChangeProps = {
  stepName?: StepNames;
} & TrackProps

export const OUTSOURCING_FLOW_CATEGORY = 'ICP1 Onboarding';


export const useTracking = (flow: FlowModel = new DefaultFlowModel()) => {
  const onboardingCategory = useRef(flow.category);

  const stepName = useAppSelector(state => state.onboarding.currentStep.stepName);
  const { company, user, isAdminLogin } = useAppSelector(state => state.auth);
  const { getUserTraits, updateTraits } = useTraits();
  const accessLvl = useSelector(selectCompanyAccessLvl);
  const companyId = useSelector(selectCompanyId);
  const companyName = useSelector(selectCompanyName);
  const companyStatus = useSelector(selectCompanyStatus);
  const getExpByName = useSelector(getExpFn);
  const trackingService = useRef(injectTrackingService());


  const commonTrackingProps = useCallback(() => ({
    name: user?.name,
    email: user?.email,
    accesslevel: accessLvl,
    createdat: company?.companyCreatedAt,
    companyname: companyName,
    companystatus: companyStatus,
    isadminlogin: isAdminLogin,
    planname: company?.pricingPlan,
    trialstartdate: company?.companyCreatedAt,
    trialenddate: company?.subscription?.expires,
    tdcompanyid: companyId,
    tduserid: user?.id,
  }), [accessLvl, company, companyId, companyName, companyStatus, isAdminLogin, user]);

  /**
   * Identifies users with Segment
   *
   * @param options The options to send to the segment `identify`
   * @param callback The function that will be executed by segment on identification.
   */
  const identify = useCallback(async (options?: SegmentOptions, callback?: () => void) => {
    try {
      let userTraits = await getUserTraits();
      userTraits = cleanObject(userTraits);
      const traits = {
        email: userTraits?.email,
        tduserid: userTraits?.tduserid,
        tdcompanyid: userTraits?.tdcompanyid,
        firstname: userTraits?.firstname,
        lastname: userTraits?.lastname,
        accesslevel: userTraits?.access_level,
        industry: userTraits?.industry ?? 'null',
        jobtitle: userTraits?.jobtitle ?? 'null',
        isadminlogin: isAdminLogin,
      };

      window.analytics.identify(userTraits, options, callback);

      if (typeof window.analytics.user === 'function') {
        await window.analytics.user().traits(traits);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('User was not identified');
    }
  }, [getUserTraits, isAdminLogin]);

  /**
   * Tracks events that do not fall under the onboarding category, such as errors, classifications, some
   * clicks, etc.
   */
  const trackAppEvent = useCallback((
    event: TrackingEvent,
    props: TrackProps = {},
  ) => trackingService.current.trackEvent(event, {
    category: onboardingCategory.current,
    ...commonTrackingProps(),
    ...props,
  }), [commonTrackingProps]);

  /**
   * Sends a segment `track` with the `category` property set to `'Onboarding'`.
   */
  const trackOnboardingStep = useCallback((event: OnboardingEvent, props: TrackProps = {}) => {
    const commonProps = {
      ...commonTrackingProps(),
      googleauth: !!getExpByName('google-auth'),
      homecta: getExpByName('popup-signup-trigger'),
      ...(event !== 'ICP1 Onboarding Complete' ? { icp1: !!getExpByName('icp1') } : {}),
    };

    return trackingService.current.trackOnboardingEvent(event, {
      ...commonProps,
      ...props,
      category: onboardingCategory.current,
    });
  }, [commonTrackingProps, getExpByName]);

  /**
   * Makes the needed track calls when the account is created
   */
  const trackAccountCreated = useCallback(async () => {
    if (
      typeof global.analytics?.user === 'function' &&
      !global.analytics.user().id()
    ) {
      await identify();
    }

    const trackSideBarPromise = trackAppEvent('SideBar Activity', {
      completedstep: 1,
      completedstepdescription: 'Create Account',
      dismissedstep: false,
    });
    const trackAccountPromise = trackOnboardingStep('Account Created');

    return Promise.all([trackSideBarPromise, trackAccountPromise]);
  }, [identify, trackOnboardingStep, trackAppEvent]);

  /**
   * Sends segment `page` call for every step change.
   */
  const trackStepChangePage = useCallback((
    stepName: Pages,
    props: TrackProps = {},
  ) => {
    const category = props.category || onboardingCategory.current;
    delete props.category;

    return trackingService.current.trackPage(stepName, {
      category,
      properties: {
        email: user?.email,
        ...props,
      },
    });
  }, [user]);

  /**
   * Sends segment `track` call for every step change.
   */
  const trackStepChangeEvent = useCallback((
    _trackingMode: CompanyTrackingMode | null,
    props: StepChangeProps = {},
  ) => {
    const { stepName: propStepName, ...allProps } = props;
    const commonProps = {
      ...commonTrackingProps(),
      category: onboardingCategory.current,
      onboardingstep: propStepName || stepName,
      trackingtype: flow.trackingMode,
    };

    return trackingService.current.trackEvent('Onboarding Step', {
      ...commonProps,
      ...allProps,
    });
  }, [stepName, commonTrackingProps, flow]);

  /**
   * Used for the tracking of the onboarding pages when they load.
   */
  const trackOnboardingPage = useCallback((stepName: StepNames) => {
    trackStepChangePage(stepName);
    trackStepChangeEvent(null, { stepName });
  }, [trackStepChangePage, trackStepChangeEvent, flow]);

  /**
   * Sends data to the logger.
   * @remarks This is not enabled yet. TBD.
   */
  const logTrackingCall = useCallback(async (name: TrackingEvent, type: 'event' | 'page') => {
    const anonymousId = utilities.uuid();
    const segmentanonymousid = utilities.segmentAnonymousId();
    const deviceInfo = await DeviceAndGeoDetector.initialize();

    const context = {
      ...{ campaign: cookieUtils.getUTMs() },
      page: {
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href,
      },
    };

    const properties = {
      ...commonTrackingProps(),
      ...deviceInfo,
      googleauth: !!getExpByName('google-auth'),
      homecta: getExpByName('popup-signup-trigger'),
      iseuaccount: DeviceAndGeoDetector.isCountryInEU(deviceInfo.country),
    };

    const payload = {
      anonymousId,
      segmentanonymousid,
      userId: user?.id,
      properties,
      context,
      type,
      [type]: name,
    };

    return TrackingRequests.logTracking(payload);
  }, [commonTrackingProps, getExpByName, user]);

  /**
   * Sends segment `track` call with name `Experiment Viewed`.
   */
  const trackExperiment = useCallback((props: TrackExperimentProps) => {
    const allProps = {
      ...commonTrackingProps(),
      ...props,
    };

    utilities.delayedFn(() => updateTraits({
      companyTraits: { name: companyName },
      userTraits: { experiment: `${ props.experimentid }-${ props.variationid }`,  workspaceName: companyName },
      sendSegment: true,
    }));

    return trackingService.current.trackExperiment(allProps);
  }, [commonTrackingProps]);

  return {
    logTrackingCall,
    trackAccountCreated,
    trackAppEvent,
    trackExperiment,
    trackOnboardingPage,
    trackOnboardingStep,
    trackStepChangePage,
    trackStepChangeEvent,
  };
};

function cleanObject(obj: Record<string, unknown> | undefined) {
  if (!obj) return {};

  const propNames = Object.getOwnPropertyNames(obj);
  for (const propName of propNames) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  }
  return obj;
}
