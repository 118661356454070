import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { utilities } from '@/utils/utilities';

import TraitsRequests from '../../requests/traits.requests';
import { AllTraits, AuthState, TraitsResponse, TraitsState } from '../../types';

import { selectCompanyId, selectUserId } from './authSlice';

const initialState: TraitsState = {
  allTraits: {
    userTraits: {},
    companyTraits: {},
  },
  loading: 'idle',
};

const fetchAllTraits = createAsyncThunk<AxiosResponse<TraitsResponse, unknown>, void, {
  state: { traits: TraitsState, auth: AuthState },
}>(
  'traits/fetchAllTraits',
  async (arg, { getState, rejectWithValue }) => {
    const state = getState();
    const companyId = selectCompanyId(state);
    const userId = selectUserId(state);

    const response = await TraitsRequests.getTraits(companyId, userId);

    if (response.status >= 400) return rejectWithValue(response);

    return response;
  },
  {
    condition: (arg, { getState }) => {
      const { traits } = getState();
      if (traits.loading === 'pending') return false;
    },
  },
);

const traitsSlice = createSlice({
  name: 'traits',
  initialState,
  reducers: {
    setAllTraits: (state, action: PayloadAction<AllTraits>) => {
      state.allTraits = {
        userTraits: {
          ...state.allTraits.userTraits,
          ...action.payload.userTraits,
        },
        companyTraits: {
          ...state.allTraits.companyTraits,
          ...action.payload.companyTraits,
        },
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllTraits.pending, state => {
        state.loading = 'pending';
      })
      .addCase(fetchAllTraits.fulfilled, (state, { payload: { data: traitsResponse }}) => {
        const companyTraits = utilities.filterObjProps(traitsResponse?.data?.companyTraits?.[0]);
        const userTraits = utilities.filterObjProps(traitsResponse?.data?.userTraits?.[0]);

        if (userTraits.id) delete userTraits.id;
        if (companyTraits.id) delete companyTraits.id;

        state.allTraits = {
          userTraits: {
            ...state.allTraits.userTraits,
            ...userTraits,
          },
          companyTraits: {
            ...state.allTraits.companyTraits,
            ...companyTraits,
          },
        };
        state.loading = 'idle';
      });
  },
});

const traitsReducer = traitsSlice.reducer;
export const traitsThunks = {
  fetchAllTraits,
};

export const { setAllTraits } = traitsSlice.actions;
export default traitsReducer;
