import React, { ComponentType, PropsWithChildren } from 'react';

type RenderIfProps = PropsWithChildren<{
  condition: boolean | undefined;
}>;

type RenderIfHoCProps = {
  renderIf?: boolean;
}

export const RenderIf: React.FC<RenderIfProps> = ({ condition, children }) => (
  <>
    { condition && children }
  </>
);

export const withRenderIf = <P extends Record<string, unknown>>(Component: ComponentType<P>) => (
  function RenderIfWrapper(props: P & RenderIfHoCProps) {
    return props.renderIf ? <Component { ...props } /> : null;
  }
);

