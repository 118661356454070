import { Axios, injectAxios } from '@/lib/axios/Axios';

import { AuthorizationResponse } from '../types';

const REQUEST_TIMEOUT = 5000;

class AuthRequests {
  public static get axiosInstance(): Axios {
    return this._axiosInstance;
  }

  private static _axiosInstance = injectAxios(`${ process.env.NEXT_PUBLIC_API_HOST }`, REQUEST_TIMEOUT);

  static authorization(token: string) {
    return this._axiosInstance
      .useTokenInterceptor(token)
      .get<AuthorizationResponse>(
        `/1.0/authorization`,
        {
          params: {
            'no-workspaces': 1,
          },
          retry: 3,
        },
      );
  }
}

export default AuthRequests;
