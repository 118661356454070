import { FC, useRef } from 'react';
import { PhoneInput, PhoneInputProps } from 'react-international-phone';
import { PhoneInputRefType } from 'react-international-phone/build/components/PhoneInput/PhoneInput';
import { css } from '@emotion/react';
import MuiTypography from '@mui/material/Typography';

import { useTrackInputEngaged } from '@/hooks';
import { withRenderIf } from '@/shared/RenderIf';

import 'react-international-phone/style.css';


type PhoneNumberInputProps = {
  phone: string;
  onChange: PhoneInputProps['onChange'];
  countryCode?: string;
  error?: string;
}

const styles = {
  Root: css`
    & .react-international-phone-country-selector-button {
      border-right: 0;
    }

    & .react-international-phone-dial-code-preview {
      padding-left: 0;
    }
  `,
};

const PhoneNumberInputComponent: FC<PhoneNumberInputProps> = ({ countryCode, error, phone, onChange }) => {
  const phoneInputRef = useRef<PhoneInputRefType>(null);
  const { trackInputEngaged } = useTrackInputEngaged('Add Phone Number Screen Engagement');

  return (
    <div>
      <PhoneInput
        ref={ phoneInputRef }
        defaultCountry={ countryCode }
        value={ phone }
        onChange={ onChange }
        onFocus={ () => trackInputEngaged() }
        disableDialCodeAndPrefix={ true }
        showDisabledDialCodeAndPrefix={ true }
        css={ styles.Root }
        inputProps={{
          'aria-label': 'Phone number',
        }}
      />

      <MuiTypography color="error" variant="caption" height={ 20 } role="alert">{ error }</MuiTypography>
    </div>
  );
};

export const PhoneNumberInput = withRenderIf(PhoneNumberInputComponent);
