import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react';

import { useAppSelector } from '@/hooks';
import { FlowModel } from '@/models/flow.model';
import CompanyRequests from '@/requests/company.requests';
import { Company } from '@/types/company.type';
import { StepCommonProps } from '@/types/steps.type';

interface DownloadAppsStepGuardProps {
  children?: ReactNode | undefined;
  flow?: FlowModel;
  variantRef?: React.RefObject<string | undefined>;
}

interface WithFlowProps extends StepCommonProps {
  flow: FlowModel;
  variantRef?: React.RefObject<string | undefined>;
}

export const DownloadAppsStepGuard: FC<DownloadAppsStepGuardProps> = ({ children }) => {
  const { company } = useAppSelector((state) => state.auth);
  const hasRun = useRef(false);

  const updateCompanySettings = useCallback(async (companyId: string) => {
    await CompanyRequests.putCompanySettings(companyId, {
      custom: {
        splitTest: {
          'promoteBrowserApp': 'B',
        },
        onboardingStep: 'downloadAppsStep',
      },
    });
  }, []);

  const handleVariantB = useCallback(async (company: Company | undefined) => {
    if (company) {
      await updateCompanySettings(company.id);
    }
  }, []);


  useEffect(() => {
    async function handleExp() {
      await handleVariantB(company);
    }

    handleExp();
    hasRun.current = true;
  }, []);


  return <>{ children }</>;
};

export const withDownloadAppsStepGuard = <P extends WithFlowProps>(Component: React.ComponentType<P>) => {
  const InnerComponent: FC<P> = (props: P) => (
    <DownloadAppsStepGuard flow={ props.flow }>
      <Component { ...props } />
    </DownloadAppsStepGuard>
  );

  InnerComponent.displayName = `withDownloadAppsStepGuard(${ Component.displayName || Component.name })`;

  return InnerComponent;
};
