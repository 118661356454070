import { useCallback, useRef } from 'react';

import { InputEngagedEvent } from '../../types';
import { useTracking } from '../useTracking';

export const useTrackInputEngaged = (event: InputEngagedEvent) => {
  const didTrackEngagement = useRef(false);
  const { trackAppEvent } = useTracking();

  const trackInputEngaged = useCallback((props: Record<string, unknown> = {}) => {
    if (didTrackEngagement.current) return;

    trackAppEvent(event, props);
    didTrackEngagement.current = true;
  }, [trackAppEvent, event]);

  return {
    trackInputEngaged,
  };
};
