import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import MuiTypography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';

import { MagicLinkInvite } from '@/components/MagicLinkInvite';
import { InviteOptionsProps } from '@/components/Steps';
import { AddMoreButton } from '@/components/Steps/InviteTeamStep/AddMoreBtn';
import { useAppSelector, useOnboardingTask, useTracking } from '@/hooks';
import { Button, ButtonProps } from '@/shared/Button';
import { Divider } from '@/shared/Divider';
import { InviteStepEvent } from '@/types/tracking.types';

export const InviteOptionsLegacy: FC<InviteOptionsProps> = ({
  flow,
  membersToInvite,
  onAddMoreClick,
  onSendInvites,
  renderMemberField,
}) => {
  const { t } = useTranslation('onboarding');
  const { trackOnboardingStep } = useTracking(flow);
  const { goToNextStep } = useOnboardingTask(flow);
  const { company } = useAppSelector(state => state.auth);
  const [skipBtnState, setSkipBtnState] = useState<'next' | 'skip'>('skip');

  const { formState } = useFormContext();

  const handleSkipBtnClick = async () => {
    const event: InviteStepEvent = skipBtnState === 'skip'
      ? 'Skipped Invite User during Onboarding'
      : 'Clicked Next On Onboarding Invite Screen';
    await trackOnboardingStep(event, {
      ...(skipBtnState === 'next' && { invite_url: company?.inviteLink }),
    });

    goToNextStep();
  };

  return <>
    <form
      onSubmit={ onSendInvites } noValidate
      aria-label="Invite team by email">
      <div className="d-flex-col row-gap-20 mb-12">
        {
          membersToInvite.map(renderMemberField)
        }
      </div>
      <AddMoreButton onClick={ onAddMoreClick } variant="legacy" />

      <div className="d-flex-col flex-align-center row-gap-4">
        <MuiLoadingButton
          variant="contained"
          type="submit"
          loading={ formState.isSubmitting }
        >
          Send Invites
        </MuiLoadingButton>
      </div>
    </form>

    <Divider mt={ 3 } mb={ 3 }>
      <MuiTypography variant="body2" color="info.light">OR</MuiTypography>
    </Divider>

    <MagicLinkInvite
      onLinkCopied={ () => setSkipBtnState('next') }
    />

    <SkipStepBtn
      variant={ skipBtnState === 'skip' ? 'text' : 'contained' }
      color={ skipBtnState === 'skip' ? 'neutral' : 'primary' }
      onClick={ handleSkipBtnClick }
      aria-label="Skip step"
    >
      { t(skipBtnState === 'skip'
        ? 'onboarding:invite_members_step.skip_btn'
        : 'onboarding:invite_members_step.next_btn')
      }
    </SkipStepBtn>
  </>;
};

const SkipStepBtn = styled((props: ButtonProps) =>
  <Button variant="text" color="neutral" { ...props } />)(() => css`
  margin: 8px auto 0;
  display: block;
`);
