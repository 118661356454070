import type { FC, ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { browserName } from 'react-device-detect';
import {
  Experiment,
  FeatureDefinition,
  GrowthBook,
  GrowthBookProvider as BaseProvider,
  Result,
} from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';

import { useAppSelector, useCountryCode, useTracking } from '@/hooks';
import { getExpFn, selectCompanyBasePlan, selectCompanyId } from '@/redux/features/authSlice';
import DeviceAndGeoDetector from '@/utils/device-detector.utils';
import { utilities } from '@/utils/utilities';

import { Env } from '../../../environments';
import { ExperimentProps } from '../../types';

type GrowthBookProviderProps = {
  features: Record<string, FeatureDefinition>;
  children?: ReactNode
}

// Create a GrowthBook instance
const growthBook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_KEY,
  decryptionKey: process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY,
  subscribeToChanges: true,
  enableDevMode: process.env.NEXT_PUBLIC_VERCEL_ENV === Env.Development,
});


export const GrowthBookProvider: FC<GrowthBookProviderProps> = ({ features, children }) => {
  const router = useRouter();
  const { trackExperiment } = useTracking();
  const { countryCode } = useCountryCode();
  const { user, hasFreeEmailDomain } = useAppSelector(state => state.auth);
  const { company } = useAppSelector(state => state.auth);
  const companyId = useAppSelector(selectCompanyId);
  const getExpByName = useAppSelector(getExpFn);
  const companySize = getExpByName('company-size');
  const homeCta = getExpByName('popup-signup-trigger');
  const basePlan = useAppSelector(selectCompanyBasePlan);

  const trackingCallback: (exp: Experiment<unknown>, result: Result<ExperimentProps>) => void =
    useCallback((_experiment, { value }: Result<ExperimentProps>) => {
      if (value?.variationid && value.experimentid !== 'GT2-3401') {
        trackExperiment(value);
      }
    }, [trackExperiment]);

  useEffect(() => {
    growthBook['context'].trackingCallback = trackingCallback;
  }, [trackingCallback]);

  useEffect(() => {
    const queryParams = router.query;
    const isMobile = DeviceAndGeoDetector.isMobile();

    growthBook.setFeatures(features || {});
    growthBook.setAttributes({
      ...growthBook.getAttributes(),
      anonymous_id: utilities.uuid(),
      mobile_user: isMobile,
      browser: browserName,
      url: router.pathname,
      country: countryCode,
      email_type: hasFreeEmailDomain ? 'free' : 'business',
      user_id: user?.id,
      company_id: companyId,
      base_plan: basePlan,
      estimated_company_size: companySize,
      home_cta: homeCta,
      company_created_date: company?.companyCreatedAt,
      tracking_mode: '',
      role: company?.role,
      ...(queryParams?.td_internal && { td_internal: queryParams?.td_internal }),
    });

    // Load most recent features async and enable refreshing features in realtime (subscribeToChanges: true)
    growthBook.loadFeatures();
  }, [router, features, hasFreeEmailDomain, countryCode, companySize, basePlan, homeCta]);

  return <BaseProvider growthbook={ growthBook }>{ children }</BaseProvider>;
};
