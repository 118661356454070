import { AxiosHeaders, InternalAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

import tokenStorageService from '@/utils/token.util';

export const tokenInterceptor = (config: InternalAxiosRequestConfig, headers: RawAxiosRequestHeaders = {}) => {
  const token = tokenStorageService.getToken();
  config.headers = new AxiosHeaders({
    ...config.headers,
    ...headers,
  });
  config.headers.setAuthorization(`JWT ${ token }`);

  return config;
};
