import { useEffect, useState } from 'react';

import DeviceDetectorUtils from '@/utils/device-detector.utils';

export const useCountryCode = () => {
  const [countryCode, setCountryCode] = useState<string>();

  useEffect(() => {
    DeviceDetectorUtils.getGeoLocData().then(({ countryCode }) => {
      const code = countryCode?.toLowerCase();
      setCountryCode(code);
    });
  }, []);

  return {
    countryCode,
  };
};
