import { injectAxios } from '@/lib/axios/Axios';
import { tokenInterceptor } from '@/lib/axios/interceptors/token.interceptor';
import SentryService from '@/utils/sentry-service.util';

import { AllTraits, TraitsResponse } from '../types';

const apiKey = process.env.NEXT_PUBLIC_DATA_INTEGRITY_KEY || '';
const TIMEOUT = 5000;

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-api-key': apiKey,
};

class TraitsRequests {
  private static _axiosInstance = injectAxios(`${ process.env.NEXT_PUBLIC_DATA_INTEGRITY_URL }`, TIMEOUT)
    .useRequestInterceptor(config => tokenInterceptor(config, defaultHeaders));

  static getTraits(companyId: string, userId: string) {
    return this._axiosInstance.get<TraitsResponse>('/api/v1/traits', {
      params: {
        tduserid: userId,
        tdcompanyid: companyId,
      },
    });
  }

  static async updateTraits(traits: AllTraits, queryParams: Record<string, string>) {
    try {
      // Make the HTTP request to update traits
      const response = await this._axiosInstance.post('/api/v1/traits', traits, {
        params: queryParams,
      });

      SentryService.captureMessageWithRetryAfter(
        `Traits: ${ traits?.companyTraits?.onboarding_step } UserId: ${ traits?.userTraits?.tduserid }`,
        {
          level: 'info',
          extra: {
            traits: traits,
          },
        },
      );

      // Return the response
      return response.data;
    } catch (error) {
      // Log errors to Sentry
      SentryService.captureExceptionWithRetryAfter(error, {
        extra: {
          errorContext: `Traits: ${ traits?.companyTraits?.onboarding_step } UserId: ${ traits?.userTraits?.tduserid }`,
          traits: traits,
        },
      });
    }
  }
}

export default TraitsRequests;
