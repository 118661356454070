import { ComponentType, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';

import { usePhoneNumberStepCheck } from '@/features/default-flow/hooks/usePhoneNumberStepCheck';
import { useOnboardingTask, useTraits } from '@/hooks';
import { PhoneNumberStep, StepCommonProps, StepInfo } from '@/types/steps.type';

import { PhoneNumberStepComponent } from './PhoneNumberStepComponent';

const withPhoneNumberStepGuard = <P extends StepCommonProps>(Component: ComponentType<P>) => (
  function PhoneNumberStepGuard(props: P) {
    const { hasPhoneStep } = usePhoneNumberStepCheck();
    const { goToNextStep, getNextStep } = useOnboardingTask(props.flow);
    const { updateTraits } = useTraits();
    const canActivate = useRef(false);

    useEffect(() => {
      if (!hasPhoneStep()) {
        updateTraits({ companyTraits: { onboarding_step: getNextStep()?.stepName }});
        goToNextStep();
        return;
      }

      canActivate.current = true;
    }, []);

    if (!canActivate.current) return <Box height={ 244 } />;
    return <Component { ...props } />;
  });

export const PhoneNumberStepInfo: StepInfo<PhoneNumberStep> = {
  Component: withPhoneNumberStepGuard(PhoneNumberStepComponent),
  id: 'phoneNumberStep',
  stepName: 'Add Phone Number Screen',
  title: 'onboarding:phone_number_step.title',
};
