import { FeatureDefinition, setPolyfills } from '@growthbook/growthbook';
import { Context, getGrowthBookSSRData } from '@growthbook/growthbook-react';
import * as crypto from 'crypto';
import { GetStaticProps } from 'next';

import { MyAppProps } from '../../pages/_app.page';


export function getServerSideGrowthBookContext(): Context {
  // Set GrowthBook polyfills
  setPolyfills({
    fetch: globalThis.fetch || require('cross-fetch'),
    EventSource: globalThis.EventSource || require('eventsource'),
    SubtleCrypto: crypto.subtle,
  });

  return {
    apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_KEY,
    decryptionKey: process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY,
  };
}

export const getGrowthBookFeatures: GetStaticProps<MyAppProps> = async () => {
  const gbFeatures = await fetchGrowthbookFeatures();

  return {
    props: {
      growthBookFeatures: gbFeatures,
    },
  };
};

export const fetchGrowthbookFeatures = async (): Promise<Record<string, FeatureDefinition>> => {
  try {
    const gbContext = getServerSideGrowthBookContext();
    const response = await getGrowthBookSSRData(gbContext);
    return response.features;
  } catch (e) {
    return {};
  }
};
