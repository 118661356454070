import { AxiosResponse } from 'axios';

import { selectCompanyId, selectUserId } from '@/redux/features/authSlice';
import { selectOnboardingPageTitle } from '@/redux/features/onboardingSlice';
import { getStore } from '@/redux/store';
import { utilities } from '@/utils/utilities';

import { Env } from '../../../environments';
import TrackingRequests from '../../requests/tracking.requests';
import {
  AnalyticsPagePayload,
  AnalyticsTrackProps,
  OnboardingEvent,
  TrackEventApiPayload,
  TrackExperimentProps,
  TrackingEvent,
  TrackPageApiPayload,
} from '../../types';
import cookieUtils from '../../utils/cookies.util';
import DeviceAndGeoDetector from '../../utils/device-detector.utils';

export interface ITrackService {
  trackPage: (page: string, data: AnalyticsPagePayload) => Promise<void | AxiosResponse>;
  trackEvent: (event: TrackingEvent, data: AnalyticsTrackProps) => Promise<void | AxiosResponse>;
}

export class ConsoleTrackService implements ITrackService {
  public trackPage(page: string, data: AnalyticsPagePayload) {
    // eslint-disable-next-line no-console
    console.debug('--TRACK PAGE--', '/ NAME:', page, '/ DATA:', data);
    return Promise.resolve();
  }

  public trackEvent(event: TrackingEvent, data: AnalyticsTrackProps) {
    // eslint-disable-next-line no-console
    console.debug('--TRACK EVENT--', '/ NAME:', event, '/ DATA:', data);
    return Promise.resolve();
  }
}

export class ServerTrackService implements ITrackService {
  public async trackEvent(event: TrackingEvent, trackingProps: AnalyticsTrackProps) {
    const { category, ...restProps } = trackingProps;

    global.analytics.track(event, {
      anonymousId: utilities.uuid(),
      userId: selectUserId(getStore().getState()),
      category,
      ...restProps,
    });

    const apiPayload: TrackEventApiPayload = {
      anonymousId: utilities.uuid(),
      userId: selectUserId(getStore().getState()),
      segmentanonymousid: utilities.segmentAnonymousId(),
      event,
      type: 'track',
      category,
      properties: {
        ...restProps,
        ...await DeviceAndGeoDetector.initialize(),
      },
      context: {
        ...this.getContext(),
      },
    };

    return TrackingRequests.postTracking(apiPayload);
  }

  public async trackPage(page: string, trackingData: AnalyticsPagePayload) {
    global.analytics.page(trackingData.category, page, {
      anonymousId: utilities.uuid(),
      userId: selectUserId(getStore().getState()),
      ...trackingData.properties,
      path: window.location.pathname,
      referrer: window.document.referrer,
      search: window.location.search,
      title: window.document.title,
      url: window.location.href,
    });

    const apiPayload: TrackPageApiPayload = {
      anonymousId: utilities.uuid(),
      userId: selectUserId(getStore().getState()),
      segmentanonymousid: utilities.segmentAnonymousId(),
      type: 'page',
      properties: {
        ...trackingData.properties,
        ...await DeviceAndGeoDetector.initialize(),
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title || selectOnboardingPageTitle(getStore().getState()),
        url: window.location.href,
      },
      category: trackingData.category,
      name: page,
      context: {
        ...this.getContext(),
      },
    };

    return TrackingRequests.postTracking(apiPayload);
  }

  private getContext() {
    return {
      campaign: cookieUtils.getUTMs(),
      groupId: selectCompanyId(getStore().getState()),
      page: {
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href,
      },
    };
  }
}

export class TrackService {
  private static _instance: TrackService;

  trackingInstance: ITrackService;

  constructor() {
    this.trackingInstance = process.env.NEXT_PUBLIC_VERCEL_ENV === Env.Development
      ? new ConsoleTrackService()
      : new ServerTrackService();
  }

  static getInstance(): TrackService {
    if (!this._instance) {
      this._instance = new TrackService();
    }

    return this._instance;
  }

  trackPage = (page: string, trackProps: AnalyticsPagePayload) =>
    this.trackingInstance.trackPage(page, trackProps);

  trackEvent = (event: TrackingEvent, trackProps: AnalyticsTrackProps) =>
    this.trackingInstance.trackEvent(event, trackProps);

  trackOnboardingEvent = (event: OnboardingEvent, properties: Record<string, unknown>) =>
    this.trackEvent(event, {
      category: 'Onboarding',
      ...properties,
    });

  /**
   * Sends a `track` call to both AJS and Node with event name set to `Experiment Viewed`. It only makes
   * the tracking call when the experiment cookie is not set.
   *
   * @param props The properties that are sent along with the event name. This should always include
   *  at least the fields from {@link ExperimentProps}.
   */
  trackExperiment(props: TrackExperimentProps) {
    const trackedName = `tracked_${ props.experimentid.replace('-', '') }`;
    const trackedCookie = cookieUtils.getCookie(trackedName);

    if (trackedCookie !== 'true') {
      cookieUtils.setCookie(trackedName, 'true');

      return this.trackingInstance.trackEvent('Experiment Viewed', {
        ...props,
        trackedon: window.location.href,
      });
    }
  }
}

export const injectTrackingService = () => TrackService.getInstance();
