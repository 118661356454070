import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import authReducer from './features/authSlice';
import onboardingReducer from './features/onboardingSlice';
import traitsReducer from './features/traitsSlice';

const makeStore = () => configureStore({
  reducer: {
    onboarding: onboardingReducer,
    auth: authReducer,
    traits: traitsReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const store = makeStore();
export const getStore = () => store;

export const wrapper = createWrapper(() => store, { debug: false });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type Store = typeof store;
