import { getCookie, removeCookie,setCookie } from './cookies.util';

type AvailableKey = 'token' | '__storage_test__';

export interface Storage {
  readonly length: number;
  clear(): void;
  getItem(key: AvailableKey): string | null;
  key(index: number): string | null;
  removeItem(key: AvailableKey): void;
  setItem(key: AvailableKey, value: string): void;
}

interface TokenStorageService {
  setToken(value: string): void;
  getToken(): string | null;
  removeToken(): void;
}

const key: AvailableKey  = 'token';

const isStorageAvailable = (storage: Storage): boolean => {
  try {
    const testKey: AvailableKey = '__storage_test__';
    storage.setItem(testKey, '__test__');
    storage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

const getStorage = (): Storage | null => {
  if (typeof window !== 'undefined') {
    if (isStorageAvailable(window.localStorage)) {
      return window.localStorage;
    } else if (isStorageAvailable(window.sessionStorage)) {
      return window.sessionStorage;
    }
  }
  return null;
};

const tokenStorageService: TokenStorageService = {
  setToken(value: string) {
    const storage = getStorage();

    if (storage) {
      storage.setItem(key, value);
    } else {
      const options = {
        secure: true,
        expires: 7,
      };
      setCookie(key, value, options);
    }
  },

  getToken() {
    const storage = getStorage();
    return storage ? storage.getItem(key) : getCookie(key);
  },

  removeToken() {
    const storage = getStorage();

    if (storage) {
      storage.removeItem(key);
    } else {
      removeCookie(key);
    }
  },
};

export default tokenStorageService;
