import { injectAxios } from '@/lib/axios/Axios';

import countriesNames from '../../data/country-names-en.json';

import { LocalStorageUtility } from './localstorage.util';

type CountryCode = keyof typeof countriesNames;

type PlatformData = {
  name: string;
  value: string;
  version: string;
}

type StoredGeoInfo = {
  ip: string;
  countryCode: string;
}

type GeoInfoResponse = {
  geo: {
    city: string;
    country: string;
    region: string;
  },
  ip: string | 'unknown';
}

const euCountryCodes = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GB',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

const dataos = [
  { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
  { name: 'Windows', value: 'Win', version: 'NT' },
  { name: 'iPhone', value: 'iPhone', version: 'OS' },
  { name: 'iPad', value: 'iPad', version: 'OS' },
  { name: 'Kindle', value: 'Silk', version: 'Silk' },
  { name: 'Android', value: 'Android', version: 'Android' },
  { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
  { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
  { name: 'Macintosh', value: 'Mac', version: 'OS X' },
  { name: 'Linux', value: 'Linux', version: 'rv' },
  { name: 'Palm', value: 'Palm', version: 'PalmOS' },
];

class DeviceAndGeoDetector {
  static hasPendingRequest = false;

  static header = () => [
    navigator?.platform,
    navigator?.userAgent,
    navigator?.appVersion,
    navigator?.vendor,
  ];

  static async getGeoLocData(): Promise<StoredGeoInfo> {
    if (this.hasPendingRequest) {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (!this.hasPendingRequest) {
            clearInterval(intervalId);
            resolve({
              ip: this.ipAddr,
              countryCode: this.countryCode,
            });
          }
        }, 80);
      });
    }

    try {
      const geoInfoKey = 'geo-info';
      let storedGeoInfo: StoredGeoInfo | null = LocalStorageUtility.getItemWithExpiry(geoInfoKey);
      if (storedGeoInfo) return storedGeoInfo;

      this.hasPendingRequest = true;
      const { data: responseData } = await injectAxios(`${ process.env.NEXT_PUBLIC_VERCEL_HOST_URL }/api`)
        .get<GeoInfoResponse>(`/geo-info`);

      this.hasPendingRequest = false;
      storedGeoInfo = {
        ip: responseData.ip,
        countryCode: responseData.geo.country,
      };
      LocalStorageUtility.setItemWithExpiry(geoInfoKey, storedGeoInfo, 7);
      return storedGeoInfo;
    } catch (e) {
      this.hasPendingRequest = false;
      return {
        ip: 'unknown',
        countryCode: 'unknown',
      };
    }
  }

  static countryCode = '';
  static ipAddr = '';
  static isMobile = () => window.matchMedia('(hover: none), (pointer: coarse)').matches
    && (/Android|Iphone|HarmonyOS/i).test(window.navigator.userAgent);

  static async initialize() {
    const agent = this.header().join(' ');
    const osName = this.matchDevice(agent, dataos);
    const userAgent = window.navigator.userAgent;
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    const height = (window.innerHeight > 0) ? window.innerHeight : screen.height;
    const ismobile = this.isMobile();

    if (!this.ipAddr || !this.countryCode) {
      const { ip, countryCode } = await this.getGeoLocData();

      // Cache the geo data so that we don't retrieve the IP for every track request
      this.ipAddr = ip;
      this.countryCode = countryCode;
    }

    return {
      width,
      height,
      deviceinfo: { os: osName, browser: this.browserDetectionName(userAgent) },
      ismobile,
      ipaddress: this.ipAddr,
      country: this.countryCode,
    };
  }

  static isChromeBrowser(): boolean {
    return DeviceAndGeoDetector.browserDetectionName(window.navigator.userAgent) === 'Google Chrome';
  }

  static browserDetectionName(userAgent: string) {
    const browserName = (regexp: RegExp) => regexp.test(userAgent);

    switch (true) {
      case browserName(/edg/i):
        return 'Microsoft Edge';
      case browserName(/trident/i):
        return 'Microsoft Internet Explorer';
      case browserName(/firefox|fxios/i):
        return 'Mozilla Firefox';
      case browserName(/opr\//i):
        return 'Opera';
      case browserName(/ucbrowser/i):
        return 'UC Browser';
      case browserName(/samsungbrowser/i):
        return 'Samsung Browser';
      case browserName(/chrome|chromium|crios/i):
        return 'Google Chrome';
      case browserName(/safari/i):
        return 'Apple Safari';
      default:
        return 'Other';
    }
  }

  static matchDevice(agent: string, data: PlatformData[]) {
    for (const datum of data) {
      const regex = new RegExp(datum.value, 'i');
      const match = regex.test(agent);

      if (match) {
        return datum.name;
      }
    }

    return 'unknown';
  }

  static isCountryInEU = (countryCode: string) => euCountryCodes.includes(countryCode);
  static getCountryName = (countryCode: string) => countriesNames[countryCode as CountryCode];
}

export default DeviceAndGeoDetector;
