import { css, Theme } from '@emotion/react';


export const styles = {
  TextWrapper: (theme: Theme) => css`
    ${ theme.breakpoints.down('sm') } {
      position: relative;
    }
  `,
  Title: (theme: Theme) => css`
    ${ theme.breakpoints.down('sm') } {
      padding-right: 40px;
      line-height: 32px;
    }
  `,
  SubTitle: (theme: Theme) => css`
    ${ theme.breakpoints.down('sm') } {
      text-align: center;
    }
  `,
  Button: css`
    flex-shrink: 0;
  `,
  TooltipIcon: (theme: Theme) => css`
    cursor: pointer;

    ${ theme.breakpoints.down('sm') } {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 2rem;
    }
  `,
};
