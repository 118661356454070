import axios from 'axios';
import semver from 'semver/preload';

import SentryService from '@/utils/sentry-service.util';

export type DownloadPlatform = '' | 'win' | 'mac' | 'linux';
export interface DownloadData {
  platform: string;
  version: string;
  appName: string;
  href: string;
  hrefMsi?: string;
  cmd?: string;
}
export class DownloadService {
  readonly downloadURL = 'https://download.timedoctor.com';
  readonly isMobileDevice: boolean
    = /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|BlackBerry|BB10|Samsung|SM-/i.test(window.navigator.userAgent);
  private static _instance: DownloadService;

  constructor() {
  }
  static getInstance(): DownloadService {
    if (!this._instance) {
      this._instance = new DownloadService();
    }

    return this._instance;
  }
  // function to determine the user OS
  determineOS(): DownloadPlatform {
    if (window.navigator.userAgent.indexOf('Win') !== -1) {
      return 'win';
    } else if (window.navigator.userAgent.indexOf('Mac') !== -1) {
      return 'mac';
    } else if (window.navigator.userAgent.indexOf('Ubuntu') !== -1
      || (window.navigator.userAgent.indexOf('Linux') !== -1 && !this.isMobileDevice)) {
      return 'linux';
    }

    return '';
  }

  // eslint-disable-next-line max-len
  getWinHref(appName: string, version: string) {
    const url = `${ this.downloadURL }/${ version }`;
    return `${ url }/windows/bitrock/${ appName }-setup-${ version }-windows.exe`;
  }

  getMacHref(appName: string, version: string ) {
    const url = `${ this.downloadURL }/${ version }`;
    const installerType = semver.gte(version, '3.14.0') ? 'pkg' : 'dmg';
    return `${ url }/macos/${ installerType }/${ appName }-${ version }-macos.${ installerType }`;
  }

  getLinuxHref(appName: string, version: string) {
    const url = `${ this.downloadURL }/${ version }`;
    return `${ url }/linux/ubuntu-18.04/interactive/${ appName }-setup-${ version }-linux-x86_64.run`;
  }

  public async getDownloadData(): Promise<DownloadData> {
    const platform =  this.determineOS();
    const appName = 'timedoctor2';
    let data: DownloadData  = { appName: '', href: '', platform: '', version: '' };

    try {
      const response = await axios.get(`${ this.downloadURL }/desktop-app-version`, {
        params: {
          os: platform,
          type: 'interactive',
          download_page: true,
        },
      });

      const { version } = response.data;
      data = {
        platform,
        version,
        appName,
        href: '#',
      };

      if (platform === 'win') {
        data.href = this.getWinHref(appName, version);
      } else if (platform === 'mac') {
        data.href = this.getMacHref(appName, version);
      } else if (platform === 'linux') {
        data.href = this.getLinuxHref(appName, version);
      }
    } catch (error) {
      SentryService.captureExceptionWithRetryAfter(error, {
        level: 'error',
        extra: {
          errorContext: 'Fail to fetch download data',
        },
      });
    }

    return data;

  }
}
export const injectDownloadService = () => DownloadService.getInstance();
