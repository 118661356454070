import { FC } from 'react';
import { css } from '@emotion/react';
import { useFeature, useGrowthBook } from '@growthbook/growthbook-react';
import Trans from 'next-translate/Trans';

import { BasicCard } from '@/shared/BasicCard';

import { breakPtDown } from '../../../../emotion.definitions';
import { useOnboardingTask, useTracking, useTraits } from '../../../hooks';
import { CompanySizeStep, StepCommonProps, StepInfo } from '../../../types';

import styles from './CompanySizeStep.module.scss';

const sizes = [
  { key: '1-25', label: '1-25' },
  { key: '26-50', label: '26-50' },
  { key: '51-100', label: '51-100' },
  { key: '101-500', label: '101-500' },
  { key: '501-1000', label: '501-1000' },
  { key: '1001+', label: '1001' },
];

export const newCompanySizes = [
  { key: '1-10', label: '1-10' },
  { key: 'n11_50', label: '11-50' },
  { key: 'n51_200', label: '51-200' },
  { key: 'n201_500', label: '201-500' },
  { key: '501-1000', label: '501-1000' },
  { key: '1001+', label: '1001' },
];

const cssStyles = {
  card: css`
    &:last-child .content:after {
      content: " or more";
    }

    ${ breakPtDown['sm'] } {
      &:last-child .content:after {
        content: "+";
      }
    }
  `,
};

const CompanySizeStepComponent: FC<StepCommonProps> = ({ flow }) => {
  const { goToNextStep, getNextStep } = useOnboardingTask(flow);
  const { trackOnboardingStep } = useTracking(flow);
  const { updateTraits } = useTraits();
  const growthBook = useGrowthBook();
  const showNewSegments = useFeature('onboarding-new-company-size-segments').value ?? false;

  async function onCardClicked(cardKey: string) {
    updateTraits({
      companyTraits: {
        tdpeopleinthecompany: cardKey,
        onboarding_step: getNextStep()?.stepName,
      },
    });

    trackOnboardingStep('Company Name and Size', {
      companysize: cardKey,
    });

    growthBook?.setAttributes({
      ...growthBook?.getAttributes(),
      estimated_company_size: cardKey,
    });

    goToNextStep();
  }

  const companySizesToShow = showNewSegments ? newCompanySizes : sizes;


  return (
    <div className={ styles.CompanySizeStep }>
      {
        companySizesToShow.map(size => (
          <BasicCard
            key={ size.key } cardKey={ size.key }
            onCardClicked={ onCardClicked }
            css={ cssStyles.card }
          >
            <span className="content | d-block txt-center color-charcoal">{ size.label }</span>
          </BasicCard>
        ))
      }
    </div>
  );
};

export const CompanySizeStepInfo: StepInfo<CompanySizeStep> = {
  Component: CompanySizeStepComponent,
  id: 'companyInfoStep',
  stepName: 'About You Screen',
  title: 'onboarding:company_size_step.title',
  Info: <Trans i18nKey="onboarding:company_size_step.info" />,
};
