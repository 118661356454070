import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { withTracking } from '@/shared/WithTracking';

import { skipPropForwardingFn } from '../../theme/utils.theme';

export type AdditionalButtonProps = {
  /** Indicates that button won't have hover state. By default, this is `false` */
  noHover?: boolean;

  /** Determines if the button will have rounder corners. By default, this is `false` */
  rounded?: boolean;

  /** Determines if the button will have all text in caps. By default, this is `true` */
  uppercase?: boolean;
}

export type ButtonProps = MuiButtonProps & AdditionalButtonProps;

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    skipPropForwardingFn<AdditionalButtonProps>(
      ['rounded', 'noHover', 'uppercase'],
      prop,
    ),
})<ButtonProps>((
  {
    noHover = false,
    rounded = false,
    uppercase = true,
    size,
    variant,
    theme,
  },
) => ({
  borderRadius: 4,
  textTransform: uppercase ? 'uppercase' : 'none',
  ...(noHover && {
    '&.MuiButton-root:hover': {
      backgroundColor: 'initial',
    },
  }),
  ...(rounded && { borderRadius: '26px' }),
  ...(size === 'medium' && {
    padding: '8px 16px',
    ...theme.typography.button,
  }),
  ...(variant === 'contained' && {
    boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.20),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0px rgba(0, 0, 0, 0.14);`,

    '&:hover': {
      boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.20),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0px rgba(0, 0, 0, 0.14);`,
    },
  }),
  ...(variant === 'outlined' && {
    borderColor: theme.palette.neutral.lighter,

    '&:hover': {
      borderColor: theme.palette.neutral.lighter,
    },
  }),
}));

export const Button = withTracking(StyledButton);
