import { defaultFlowSteps, DownloadAppsStepInfo } from '@/components/Steps';
import { FlowModel } from '@/models/flow.model';
import { CompanyTrackingMode } from '@/types/company.type';
import { DefaultStepsInfo } from '@/types/steps.type';

export class DefaultFlowModel implements FlowModel {
  private _trackingMode: CompanyTrackingMode | undefined;
  private _steps: DefaultStepsInfo[] = defaultFlowSteps();
  readonly category = 'Onboarding';
  readonly name = 'default';
  readonly onboardingCompleteStep = 'Completed Onboarding';
  readonly onboardingCompleteEvent = 'Onboard Complete';
  readonly showSpecialOffer = true;

  get trackingMode(): CompanyTrackingMode | undefined {
    return this._trackingMode;
  }

  set trackingMode(value: CompanyTrackingMode | undefined) {
    this._trackingMode = value;
  }

  getSteps = () => this._steps;

  useShortenedFlow(): void {

  }

  addDownloadAppStep() {
    this._steps.push(DownloadAppsStepInfo);
  }
}

export const defaultFlowModel = new DefaultFlowModel();
