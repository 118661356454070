import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch, useAppSelector, useTracking, useTraits } from '@/hooks';
import { FlowModel } from '@/models/flow.model';
import { getExpFn, selectCompanyId, selectCompanyName } from '@/redux/features/authSlice';
import { setShowCcOffer } from '@/redux/features/onboardingSlice';
import CompanyRequests from '@/requests/company.requests';
import UserRequests from '@/requests/user.requests';
import { StepNames } from '@/types/steps.type';
import { CompletedOnboarding } from '@/types/traits.type';
import DeviceAndGeoDetector from '@/utils/device-detector.utils';

export const useAccountCreated = (flow: FlowModel) => {
  const dispatch = useAppDispatch();

  const { company, user, hasFreeEmailDomain } = useAppSelector(state => state.auth);
  const { logTrackingCall, trackAccountCreated, trackAppEvent, trackOnboardingStep } = useTracking(flow);
  const { updateTraits } = useTraits();

  const companyId = useSelector(selectCompanyId);
  const companyName = useSelector(selectCompanyName);
  const getExpByName = useSelector(getExpFn);

  /**
   * Takes care of updating the company name in the settings and tracking the email type.
   */
  const trackEmailType = useCallback(() => trackAppEvent('Email Type', {
    classification: hasFreeEmailDomain ? 'free' : 'business',
    companyname: companyName,
    trackingtype: flow.trackingMode,
  }), [companyName, hasFreeEmailDomain, user, flow, trackAppEvent]);

  const updateSettings = useCallback(async () => {
    const screenshotsInterval = 1800;
    const companySettingsPromise = CompanyRequests.putCompanySettings(companyId, {
      name: companyName?.trim(),
      custom: {
        trackedAccountCreated: true,
        showSpecialOffer: flow.showSpecialOffer,
      },
      trackingMode: flow.trackingMode,
      screenshots: screenshotsInterval,
    });

    const userSettingsPromise = UserRequests.updateSettings(companyId, {
      screenshots: screenshotsInterval,
    });

    await Promise.all([companySettingsPromise, userSettingsPromise]);
  }, [companyId, companyName, flow]);

  const setInitialTraits = useCallback(async (stepName: StepNames | CompletedOnboarding) => {
    const { contactCountry } = await getGeoInfo();
    const companySize = company?.splitTest?.find(st => st.name === 'company-size' && st.value);
    const phoneNumber = company?.splitTest?.find(st => st.name === 'phone-number' && st.value);
    const isFromGoogleAuth = company?.splitTest?.find(st => st.name === 'google-auth' && st.value);

    await updateTraits(
      {
        companyTraits: {
          name: companyName,
          onboarding_step: stepName,
          provider: company?.subscription?.provider,
          tdpeopleinthecompany: companySize?.value,
        },
        userTraits: {
          workspace_tracking_type: flow.trackingMode,
          contact_country: contactCountry,
          workspaceName: companyName,
          phonenumber: phoneNumber?.value,
        },
        isAccountCreated: true,
        queryParams: { ...flow.traitsQueryParams },
      },
    );
    if (!isFromGoogleAuth) {
      trackCompanySize(companySize?.value);
    }

  }, [companyName, company, flow, updateTraits]);

  const hasLoggedTrackingCall = false;

  const makeTrackCalls = useCallback(async () => {
    const { isUEMember } = await getGeoInfo();

    trackEmailType();

    if (isUEMember && flow.name === 'onboarding-sso') {
      await trackOnboardingStep('ICP EU-UK Classified Company', {
        reason: 'EU-UK Company Detected',
      });
    }

    if (!hasLoggedTrackingCall) {
      await trackAccountCreated();
      logTrackingCall('Account Created', 'event');
    }

  }, [trackEmailType, logTrackingCall, trackOnboardingStep, trackAccountCreated, flow]);

  const checkPartnerStack = useCallback(() => {
    if (!!getExpByName('partnerStack') && window?.dataLayer && user) {
      window.dataLayer.push({
        event: 'partnerStackSignUp',
        partnerStackEmail: user.email,
        partnerStackName: user.name,
        partnerStackCustKey: companyId,
      });
    }
  }, [user, companyId, getExpByName]);

  const trackCompanySize = useCallback((companySize: string | unknown) => {
    trackOnboardingStep('Company Name and Size', {
      companysize: companySize,
    });
  }, [trackOnboardingStep]);
  /**
   * Executes all the tasks that are needed when the account is created. That includes:
   * traits update,tracking calls and update of the company settings.
   */
  const onAccountCreated = useCallback(
    async (stepName: StepNames | CompletedOnboarding) => {
      if (flow.showSpecialOffer) dispatch(setShowCcOffer(true));

      const hasAccountCreatedTracked = company?.companySettings?.custom?.trackedAccountCreated || false;
      if (!hasAccountCreatedTracked) {
        await setInitialTraits(stepName);
        await makeTrackCalls();
        await updateSettings();
        checkPartnerStack();
      }

      CompanyRequests.putCompanySettings(companyId, {
        workScheduleFeature: true,
        payrollFeature: !company?.pricingPlan?.includes('basic'),
        tasksMode: 'preset',
        custom: {
          browserExtensionEnabled: true,
        },
      });
    },
    [
      checkPartnerStack,
      company,
      companyId,
      dispatch,
      flow,
      makeTrackCalls,
      setInitialTraits,
      updateSettings,
    ],
  );

  return {
    onAccountCreated,
  };
};

const getGeoInfo = async () => {
  const geoInfo = await DeviceAndGeoDetector.initialize();
  const isUEMember = DeviceAndGeoDetector.isCountryInEU(geoInfo.country);
  const contactCountry = DeviceAndGeoDetector.getCountryName(geoInfo.country);

  return { isUEMember, contactCountry };
};
