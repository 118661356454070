import { useCallback } from 'react';
import { useGrowthBook } from '@growthbook/growthbook-react';

import { PhoneNumberStepInfo } from '@/components/Steps';
import { useAppSelector } from '@/hooks';

const SIZES_WITH_NO_PHONE = ['1-25', '1-10'];

export const usePhoneNumberStepCheck = () => {
  const growthBook = useGrowthBook();
  const { company } = useAppSelector(state => state.auth);

  const hasPhoneStep = useCallback(() => {
    if (company?.companySettings?.custom?.onboardingStep === PhoneNumberStepInfo.id) return true;

    const companySize = growthBook?.getAttributes().estimated_company_size;

    // If we don't have the company size, we won't show the phone step
    if (!companySize) return false;

    return !SIZES_WITH_NO_PHONE.includes(companySize);
  }, [growthBook, company]);

  return {
    hasPhoneStep,
  };
};
