import { useCallback } from 'react';

import { setHasLoader } from '../../redux/features/onboardingSlice';
import { useAppDispatch } from '../storeHooks';


export const useLoader = () => {
  const dispatch = useAppDispatch();

  const showLoader = useCallback(() => dispatch(setHasLoader(true)), [dispatch]);
  const hideLoader = useCallback(() => dispatch(setHasLoader(false)), [dispatch]);

  return {
    showLoader,
    hideLoader,
  };
};
