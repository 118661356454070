type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type BreakpointsMap<T extends string> = {
  [key in T]: string;
};


const breakpoints: [Breakpoints, number][] = [['xs', 380], ['sm', 576], ['md', 768], ['lg', 992], ['xl', 1200]];

export const breakPtDown = Object.fromEntries(
  breakpoints.map(([bpStr, bpNum]) => [bpStr, `@media (max-width: ${ bpNum - 1 }px)`]),
) as BreakpointsMap<Breakpoints>;
