import { injectAxios } from '@/lib/axios/Axios';
import { tokenInterceptor } from '@/lib/axios/interceptors/token.interceptor';

import { BulkInvitePayload, BulkInviteResponse, UserSettings } from '../types';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const TIMEOUT = 15000;

class UserRequests {
  private static _axiosInstance = injectAxios(`${ process.env.NEXT_PUBLIC_API_HOST }`, TIMEOUT)
    .useRequestInterceptor(config => tokenInterceptor(config, defaultHeaders));

  static bulkInvite(data: BulkInvitePayload, companyId: string) {
    return this._axiosInstance.post<BulkInviteResponse>(
      '/1.1/invitations/bulk',
      data,
      {
        params: { company: companyId },
      },
    );
  }

  static updateSettings(companyId: string, settings: Partial<UserSettings>) {
    return this._axiosInstance.put(
      '/1.0/users/me',
      settings,
      {
        params: { company: companyId },
      },
    );
  }
}

export default UserRequests;
