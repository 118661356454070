import Trans from 'next-translate/Trans';

import { ComputerOwnerStepComponent } from '@/components/Steps/ComputerOwnerStep/ComputerOwnerStep';
import { ComputerOwnerStep, StepInfo } from '@/types/steps.type';

export const ComputerOwnerStepInfo: StepInfo<ComputerOwnerStep> = {
  Component: ComputerOwnerStepComponent,
  id: 'computerOwnerStep',
  stepName: 'Tracking Owners Screen',
  title: 'onboarding:computer_owner_step.title',
  desc: 'onboarding:computer_owner_step.description',
  Info: <Trans i18nKey="onboarding:computer_owner_step.info" />,
};
