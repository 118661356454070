import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useFeature } from '@growthbook/growthbook-react';
import debounce from '@mui/utils/debounce';

import { InviteMemberTypes, MemberField } from '@/components/InviteMember';
import { InviteOptions } from '@/components/Steps/InviteTeamStep/InviteOptions';
import { InviteOptionsLegacy } from '@/components/Steps/InviteTeamStep/InviteOptionsLegacy';
import { useAppDispatch, useOnboardingTask, useTracking } from '@/hooks';
import { selectCompanyId, selectUserEmail } from '@/redux/features/authSlice';
import { setInviteSuccessCount } from '@/redux/features/onboardingSlice';
import UserRequests from '@/requests/user.requests';
import { AccessLevel, ExperimentProps, Member, StepCommonProps } from '@/types/index';

import { useDownloadApps } from '../../../hooks/useDownloadApps';


const DEFAULT_DEBOUNCE_TIME = 100;
const defaultMember: Member = { email: '', role: 'user' };

type InviteFormProps = {
  initialMembersToInvite?: number; // property to set default amount of invite fields
  trackSkipComponent?: string;
} & StepCommonProps;

export const InviteStepCtx = createContext<{
  didCopyLink: boolean, setDidCopyLink: Dispatch<SetStateAction<boolean>>
}>(
  {
    didCopyLink: false,
    setDidCopyLink: () => ({}),
  },
);

const InviteStepProvider = InviteStepCtx.Provider;

export const InviteTeamStep: React.FC<InviteFormProps> = ({ flow }) => {
  const ownerEmail = useSelector(selectUserEmail);
  const companyId = useSelector(selectCompanyId);
  const dispatch = useAppDispatch();
  const { trackAppEvent, trackOnboardingStep } = useTracking(flow);
  const { goToNextStep } = useOnboardingTask(flow);
  const showOnboardingVariant = useFeature<ExperimentProps>('revamp-invite-step');
  const revampStepExp = showOnboardingVariant.value?.variationid as 'A' | 'B' | undefined;
  const { executeBrowserPromoTracking } = useDownloadApps(flow);

  const [didTrackInputClick, setTrackInputClick] = useState(false);
  const [didTrackInputChange, setTrackInputChange] = useState(false);
  const [membersToInvite, setMembersToInvite] = useState<Member[]>([
    { ...defaultMember },
    ...(revampStepExp !== 'B' ? [{ ...defaultMember }] : []),
  ]);
  const [didCopyLink, setDidCopyLink] = useState<boolean>(false);

  const formProps = useForm({
    mode: 'all',
  });
  const { handleSubmit, setError: setFormError, trigger } = formProps;

  const handleAddMoreClicked = () => {
    trackAppEvent('Clicked Add More On Onboarding Invite Screen');
    setMembersToInvite(prevState => [...prevState, { ...defaultMember }]);
  };

  const handleAccessLvlChange = (accessLvl: AccessLevel, i: number) => {
    membersToInvite[i].role = accessLvl;
    setMembersToInvite([...membersToInvite]);
  };

  const onInvalidFormSubmit = async () => {
    trackAppEvent('Failed Onboarding Invite Submission', { reason: 'invalid email address' });
  };

  const onValidFormSubmit = async (data: Record<string, string>) => {
    if (!didCopyLink && hasAllEmailsEmpty(data)) {
      setFormError('email-0', { type: 'async', message: 'Please provide at least one email' });
      trackAppEvent('Failed Onboarding Invite Submission', { reason: 'email address cannot be blank' });
      return;
    }

    if (checkRepeatedEmails(data) === -1) {
      trackAppEvent('Failed Onboarding Invite Submission', { reason: 'Duplicated email address' });
      return;
    }

    const { wasSuccessful, invitedMembers, invitedCount } = await sendInvites();

    if (wasSuccessful && invitedMembers) {
      const totalnumberofinvited = invitedMembers.length;
      const numberofuserinvited = invitedMembers.filter(member => member.role === 'user').length;
      const numberofadmininvited = invitedMembers.filter(member => member.role === 'admin').length;
      const numberofmanagerinvited = invitedMembers.filter(member => member.role === 'manager').length;

      await trackOnboardingStep('Invite User during Onboarding', {
        inviteeemails: { ...invitedMembers.map(member => member.email) },
        totalnumberofinvited,
        numberofuserinvited,
        numberofadmininvited,
        numberofmanagerinvited,
      });

      if (revampStepExp === 'B') {
        await trackOnboardingStep('Clicked Next On Onboarding Invite Screen', {
          inviteeemails: { ...invitedMembers.map(member => member.email) },
          totalnumberofinvited,
          numberofuserinvited,
          numberofadmininvited,
          numberofmanagerinvited,
        });
      }
    }

    if (revampStepExp !== 'B') {
      await trackAppEvent('SideBar Activity', {
        completedstep: 3,
        completedstepdescription: 'Add your first employee',
        dismissedstep: false,
      });
    }

    UserRequests.updateSettings(companyId, {
      custom: {
        completedStep: { addEmployee: true },
      },
    });


    await executeBrowserPromoTracking();

    goToNextStep({ stepData: { invitedCount }});
  };

  const hasAllEmailsEmpty = (data: Record<string, string>) => {
    const dataEntries = Object.entries(data);
    let emptyMails = 0;

    for (const [, email] of dataEntries) {
      !email.trim() && emptyMails++;
    }

    return emptyMails === dataEntries.length;
  };

  const checkRepeatedEmails = (data: Record<string, string>) => {
    let returnCode: 0 | -1 = 0;
    const dataEntries = Object.entries(data);
    const emails: string[] = [];

    for (let i = 0; i < dataEntries.length; i++) {
      const [_, emailEntry] = dataEntries[i];
      membersToInvite[i].email = emailEntry.trim();

      if (emails.some(email => emailEntry && email === emailEntry) || isOwnerEmail(emailEntry)) {
        setFormError(`email-${ i }`, { type: 'async', message: 'This email address has already been added.' });
        returnCode = -1;
      } else {
        emails.push(emailEntry);
      }
    }

    return returnCode;
  };

  const isOwnerEmail = (email: string) => email === ownerEmail;


  /**
   * Sends a bulk invite to the emails added through the UI.
   *
   * @returns `true` when all invites are sent successfully to the given recipients.
   */
  const sendInvites = async () => {
    try {
      const filteredMembers = membersToInvite.filter(member => member.email);
      const { data: responseData } = await UserRequests.bulkInvite({ users: filteredMembers }, companyId);

      let inviteSuccessCount = 0;
      for (const [i, inviteData] of responseData.data.entries()) {
        const keyEmail = filteredMembers[i].email;
        if (inviteData[keyEmail]?.status === 'sent') {
          inviteSuccessCount++;
        }
      }

      dispatch(setInviteSuccessCount(inviteSuccessCount));

      return {
        invitedCount: inviteSuccessCount,
        wasSuccessful: inviteSuccessCount === filteredMembers.length,
        invitedMembers: filteredMembers,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error sending invite emails', e);
      return {
        invitedCount: 0,
        wasSuccessful: false,
        invitedMembers: undefined,
      };
    }
  };

  const handleInputClick: InviteMemberTypes.InputClickFn = ({ role, email }) => {
    if (!didTrackInputClick) {
      trackAppEvent('Invite users onboarding email active', {
        inviteeusertype: role,
        inviteuseremail: email,
      });

      setTrackInputClick(true);
    }
  };

  const handleInputChange: InviteMemberTypes.InputChangeFn = debounce(
    async (inputId, { email, role }) => {
      const isValidEmail = await trigger(inputId);

      if (didTrackInputChange) return;

      if (isValidEmail && !isOwnerEmail(email)) {
        trackAppEvent('Invite Users Onboarding typed valid email', {
          inviteeusertype: role,
          inviteuseremail: email,
        });

        setTrackInputChange(true);
      }
    },
    DEFAULT_DEBOUNCE_TIME,
  );

  return (
    <InviteStepProvider value={{ didCopyLink, setDidCopyLink }}>
      <FormProvider { ...formProps }>
        { revampStepExp !== 'B' ? (
          <InviteOptionsLegacy
            flow={ flow }
            onSendInvites={ handleSubmit(onValidFormSubmit, onInvalidFormSubmit) }
            onAddMoreClick={ handleAddMoreClicked }
            membersToInvite={ membersToInvite }
            renderMemberField={ (member, i) => (
              <MemberField
                key={ i }
                inputId={ `email-${ i }` }
                member={ member }
                variant="legacy"
                onInputClick={ handleInputClick }
                onInputChange={ handleInputChange }
                onAccessLvlChange={ accessLvl => handleAccessLvlChange(accessLvl, i) }
              />
            ) }
          />
        ) : (
          <InviteOptions
            flow={ flow }
            onSendInvites={ handleSubmit(onValidFormSubmit, onInvalidFormSubmit) }
            onAddMoreClick={ handleAddMoreClicked }
            membersToInvite={ membersToInvite }
            renderMemberField={ (member, i) => (
              <MemberField
                key={ i }
                inputId={ `email-${ i }` }
                member={ member }
                variant="exp"
                onInputClick={ handleInputClick }
                onInputChange={ handleInputChange }
                onAccessLvlChange={ accessLvl => handleAccessLvlChange(accessLvl, i) }
              />
            ) }
          />
        ) }
      </FormProvider>
    </InviteStepProvider>
  );
};
