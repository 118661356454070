import type React from 'react';
import styled from '@emotion/styled';
import type { Property } from 'csstype';

export type FlexBoxProps<C extends React.ElementType = 'div'> = {
  /* Flex box properties */
  alignItems?: Property.AlignItems;
  flex?: Property.Flex;
  flexDirection?: Property.FlexDirection;
  flexFlow?: Property.FlexFlow;
  flexWrap?: Property.FlexWrap;
  gap?: Property.Gap<string | number>;
  justifyContent?: Property.JustifyContent;
  columnGap?: Property.ColumnGap<string | number>;
  rowGap?: Property.RowGap<string | number>;
  column?: boolean;

  /* Common changed styles */
  mt?: Property.MarginTop<string | number>;
  mb?: Property.MarginBottom<string | number>;
  pt?: Property.PaddingTop<string | number>;
  pb?: Property.PaddingBottom<string | number>;
  width?: Property.Width<string | number>;
  height?: Property.Height<string | number>;
  maxWidth?: Property.MaxWidth<string | number>;
  minWidth?: Property.MinWidth<string | number>;
} & React.ComponentProps<C>

export const FlexBox = styled.div<FlexBoxProps>((
  {
    column,
    mt, mb, pt, pb,
    theme,
    ...all
  },
) => ({
  display: 'flex',
  flexDirection: column ? 'column' : all.flexDirection,
  alignItems: all.alignItems,
  justifyContent: all.justifyContent,
  flex: all.flex,
  flexFlow: all.flexFlow,
  flexWrap: all.flexWrap,
  gap: theme.utils.spacing(all.gap),
  columnGap: theme.utils.spacing(all.columnGap),
  rowGap: theme.utils.spacing(all.rowGap),

  marginTop: theme.utils.spacing(mt),
  marginBottom: theme.utils.spacing(mb),
  paddingTop: theme.utils.spacing(pt),
  paddingBottom: theme.utils.spacing(pb),
  width: all.width,
  height: all.height,
  maxWidth: all.maxWidth,
  minWidth: all.minWidth,
}));
