import { CompanySizeStepInfo } from '@/components/Steps/CompanySizeStep';
import { PhoneNumberStepInfo } from '@/components/Steps/PhoneNumberStep';

import { DefaultStepsInfo, GoogleAuthStepsInfo } from '../../types';

import { ComputerOwnerStepInfo } from './ComputerOwnerStep';
import { FocusAreaStepInfo } from './FocusAreaStep';
import { InviteTeamStepInfo } from './InviteTeamStep';
import { TrackingModeStepInfo } from './TrackingModeStep';

export const defaultFlowSteps = (): DefaultStepsInfo[] => [
  FocusAreaStepInfo,
  ComputerOwnerStepInfo,
  TrackingModeStepInfo,
  InviteTeamStepInfo,
];

export const ssoFlowSteps = (): GoogleAuthStepsInfo[] => [
  CompanySizeStepInfo,
  PhoneNumberStepInfo,
  FocusAreaStepInfo,
  ComputerOwnerStepInfo,
  TrackingModeStepInfo,
  InviteTeamStepInfo,
];

export * from './CompanySizeStep';
export * from './ComputerOwnerStep';
export * from './DownloadAppsStep';
export * from './FocusAreaStep';
export * from './InviteTeamStep';
export * from './PhoneNumberStep';
export * from './TrackingModeStep';

