import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getExpFn, selectCompanyId } from '@/redux/features/authSlice';
import { setAllTraits, traitsThunks } from '@/redux/features/traitsSlice';
import { utilities } from '@/utils/utilities';

import TraitsRequests from '../../requests/traits.requests';
import { AllTraits, CompanySubscription, CompanyTraits, CompletedOnboarding, StepNames } from '../../types';
import cookieUtils from '../../utils/cookies.util';
import DeviceAndGeoDetector from '../../utils/device-detector.utils';
import { useAppDispatch, useAppSelector } from '../storeHooks';

type TraitsArgs = {
  companyTraits?: Record<string, unknown>;
  userTraits?: Record<string, unknown>;
  isAccountCreated?: boolean;
  sendSegment?: boolean;
  provider?: CompanySubscription['provider'];
}

type UpdateTraitsArgs = {
  companyTraits?: {
    onboarding_step?: StepNames | CompletedOnboarding;
    provider?: CompanySubscription['provider'];
    [key: string]: unknown;
  };
  userTraits?: Record<string, unknown>;
  sendSegment?: boolean;
  isAccountCreated?: boolean;
  queryParams?: Record<string, string>;
}

export const useTraits = () => {
  const dispatch = useAppDispatch();
  const { allTraits } = useAppSelector(state => state.traits);
  const { company, user, hasFreeEmailDomain } = useAppSelector(state => state.auth);
  const companyId = useSelector(selectCompanyId);
  const getExpByName = useSelector(getExpFn);

  useEffect(() => {
    if (!Object.keys(allTraits.companyTraits ?? {}).length && companyId) {
      dispatch(traitsThunks.fetchAllTraits());
    }
  }, [allTraits, companyId, dispatch]);

  const accountCreatedTraits = useCallback(async () => {
    const { countryCode } = await DeviceAndGeoDetector.getGeoLocData();

    return {
      anonymousId: utilities.uuid(),
      segmentanonymousid: utilities.segmentAnonymousId(),
      googleauth: !!getExpByName('google-auth'),
      iseuaccount: DeviceAndGeoDetector.isCountryInEU(countryCode),
    };
  }, [getExpByName]);


  const traitsInCommon = useCallback((keySelector: keyof AllTraits) => ({
    client_id: allTraits?.userTraits?.client_id ?? utilities.getDataLayer('GAClientID_DL'),
    createdAt: company?.companyCreatedAt,
    tdcompanyid: company?.id,
    utmCampaign: allTraits[keySelector]?.utmCampaign || cookieUtils.getUTMsByName('utm_campaign'),
    utmContent: allTraits[keySelector]?.utmContent || cookieUtils.getUTMsByName('utm_content'),
    utmMedium: allTraits[keySelector]?.utmMedium || cookieUtils.getUTMsByName('utm_medium'),
    utmSource: allTraits[keySelector]?.utmSource || cookieUtils.getUTMsByName('utm_source'),
    utmTerm: allTraits[keySelector]?.utmTerm || cookieUtils.getUTMsByName('utm_term'),
  }), [allTraits, company]);

  const getUserTraits = useCallback((excludeAllUserTraits = false) => {
    if (!company) return {};
    if (!user) return {};

    const nameArr = user?.name.split(/ (.+)/);
    const firstname = allTraits?.userTraits?.firstname ?? nameArr[0];
    const lastname = allTraits?.userTraits?.lastname ?? nameArr[1];
    const allUserTraits = !excludeAllUserTraits ? allTraits?.userTraits : {} ;
    return {
      ...traitsInCommon('userTraits'),
      ...allUserTraits,
      access_level: (company.role === 'user') ? 'regular user' : company.role,
      client_id: allTraits?.userTraits?.client_id ?? utilities.getDataLayer('GAClientID_DL'),
      email: allTraits?.userTraits?.email ?? user.email,
      firstname,
      lastname,
      name: user?.name,
      numberofcompanies: allTraits?.userTraits?.numberofcompanies ?? user.companies.length,
      tdEmail: user.email,
      tdcompanyid: company.id,
      tduserid: user.id,
      timezone: allTraits?.userTraits?.timezone ?? company.timezone,
      workspaceId: allTraits?.userTraits?.workspaceId ?? company.id,
      workspaceName: allTraits?.userTraits?.workspaceName ?? company.name,
    } as Record<string, unknown>;
  }, [allTraits, company, user, traitsInCommon]);

  const getCompanyTraits = useCallback((excludeAllCompanyTraits = false) => {
    if (!company) return;

    const tdRef = cookieUtils.getCookie('td_referrer') || null;

    const commonTraits = traitsInCommon('companyTraits');
    const allCompanyTraits = !excludeAllCompanyTraits ? allTraits?.companyTraits : {};
    const newCompanyTraits = {
      ...commonTraits,
      ...allCompanyTraits,
      client_id: allTraits?.companyTraits?.client_id ?? utilities.getDataLayer('GAClientID_DL'),
      fbclid: allTraits?.companyTraits?.fbclid ?? utilities.getDataLayer('fbclid'),
      gclid: allTraits?.companyTraits?.gclid ?? utilities.getDataLayer('gclid'),
      home_cta: allTraits?.companyTraits?.home_cta ?? getExpByName('popup-signup-trigger'),
      name: allTraits?.companyTraits?.name ?? company.name,
      taskMode: allTraits?.companyTraits?.taskMode ?? company.companySettings?.tasksMode,
      td_referrer: allTraits?.companyTraits?.td_referrer ?? tdRef,
      td_website_url: allTraits?.companyTraits?.td_website_url ?? getExpByName('website'),
      tdapptype: 'TD2',
      timezone: company.companyTimezone,
    } as CompanyTraits;

    return newCompanyTraits;
  }, [allTraits, company, getExpByName, traitsInCommon]);

  const prepareTraits = useCallback(async (
    {
      companyTraits = {},
      userTraits = {},
      isAccountCreated = false,
      sendSegment = false,
      ...commonTraits
    }: TraitsArgs,
  ) => {
    if (!user) return null;

    const companyIdAsDomain = company?.id?.replace(/[_-]/g, '');
    const emailDomain = typeof companyTraits?.website === 'string'
      ? `${ companyTraits.website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0] }`
      : user.email.substring(user.email.lastIndexOf('@') + 1);

    const userDomain = hasFreeEmailDomain ? `www.${ companyIdAsDomain }.com` : emailDomain;
    const origDomain = hasFreeEmailDomain ? userDomain : emailDomain;

    let allUserTraits = {
      ...getUserTraits(true),
      ...userTraits,
    };

    let allCompanyTraits = {
      ...getCompanyTraits(true),
      ...companyTraits,
    };

    const tdWebsiteUrl = !allCompanyTraits.td_website_url || companyTraits?.website
      ? origDomain
      : allCompanyTraits.td_website_url;
    allCompanyTraits.td_website_url = cleanUrl(tdWebsiteUrl, true);

    if (isAccountCreated) {
      const { googleauth, ...restTraits } = await accountCreatedTraits();

      allCompanyTraits = {
        ...allCompanyTraits,
        ...restTraits,
      };

      allUserTraits = { ...allUserTraits, googleauth };
    }

    const traits = {
      userTraits: allUserTraits,
      companyTraits: allCompanyTraits,
      send_segment: sendSegment,
      isAccountCreated,
      ...commonTraits,
    };

    if (!allTraits.companyTraits && !isAccountCreated) {
      return null;
    }

    dispatch(setAllTraits({
      userTraits: allUserTraits,
      companyTraits: allCompanyTraits,
    }));
    if (traits.companyTraits?.status) {
      delete traits.companyTraits.status;
    }

    return traits;
  }, [allTraits, accountCreatedTraits, company, dispatch, hasFreeEmailDomain, user, getCompanyTraits, getUserTraits]);

  const updateTraits = useCallback(async ({ queryParams = {}, ...allParams }: UpdateTraitsArgs) => {
    const traits = await prepareTraits({ ...allParams });
    if (!traits) return;

    const allQueryParams = {
      ...queryParams,
      company: companyId,
    };

    return TraitsRequests.updateTraits(traits, allQueryParams);
  }, [prepareTraits, companyId]);

  return {
    updateTraits,
    getUserTraits,
    getCompanyTraits,
  };
};

function cleanUrl(url: string, withWWW: boolean) {
  if (withWWW) {
    return `https://www.${ url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0] }`;
  }
  return `https://${ url.replace(/^(?:https?:\/\/)?(?:)?/i, '').split('/')[0] }`;
}
