import { styled } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const TooltipComponent = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip { ...props } classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${ tooltipClasses.tooltip }`]: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.neutral.dark,
    color: '#fff',
    maxWidth: '500px',
    ...theme.typography.caption,
  },

  [theme.breakpoints.down('sm')]: {
    [`& .${ tooltipClasses.tooltip }`]: {
      maxWidth: 'calc(100vw - 32px)',
    },
  },
}));
