import React, { FC } from 'react';
import MuiAddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import MuiButton from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';

import { Button } from '@/shared/Button';

type AddMoreProps = {
  onClick: () => void;
  variant?: 'legacy' | 'exp';
  children?: React.ReactNode;
}

export const AddMoreButton: FC<AddMoreProps> = ({ variant, onClick }) => {
  const { t } = useTranslation();
  return (
    variant === 'legacy'
      ? <LegacyAddMore onClick={ onClick }>+ Add more</LegacyAddMore>
      : <NewAddMore
        onClick={ onClick }>{ t('onboarding:invite_members_step.add_more_btn') }</NewAddMore>
  );
};

const LegacyAddMore: FC<AddMoreProps> = ({ onClick, children }) => (
  <div className="txt-left txt-center-md">
    <MuiButton
      onClick={ onClick }
      size="small"
      style={{ textTransform: 'none', fontWeight: 500, width: 'fit-content' }}
      type="button"
      aria-label="Add more user"
    >
      { children }
    </MuiButton>
  </div>
);

const NewAddMore: FC<AddMoreProps> = ({ onClick, children }) => (
  <Box>
    <Button
      sx={ (theme: Theme) => ({ color: 'info.main', ...theme.typography.subtitle2 }) }
      onClick={ onClick }
      size="small"
      type="button"
      aria-label="Add another user"
      uppercase={ false }
    >
      <MuiAddIcon color="info" sx={{ mr: 1 }} />
      { children }
    </Button>
  </Box>
);
