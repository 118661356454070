import { useEffect, useRef } from 'react';

import { useTracking } from '../useTracking';

export const useTrackPage = () => {
  const didTrackPage = useRef(false);
  const { trackStepChangePage } = useTracking();

  useEffect(() => {
    if (didTrackPage.current) return;

    trackStepChangePage('Special Offer');
    didTrackPage.current = true;
  }, [trackStepChangePage]);
};
