import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { rejectedResponseInterceptor } from './interceptors/rejected-response.interceptor';

type TRes = Record<string, unknown>
type Res<T> = AxiosResponse<T>
type Data = Record<string, unknown>

export const DEFAULT_TIMEOUT = 5000;

export class Axios {
  private readonly _instance: AxiosInstance;
  public get instance(): AxiosInstance {
    return this._instance;
  }

  /**
   * Defines an axios instance with the API base url. When there is an existing
   * instance, it will return such instance.
   *
   * @param baseURL - The url that will be used .
   * @param timeout - The time in **ms** that axios will wait for a request to receive a response. By
   *  default, this is `5000` ms.
   */
  constructor(baseURL: string, timeout = DEFAULT_TIMEOUT) {
    this._instance = axios.create({
      baseURL,
      timeout,
    });

    this._instance.interceptors.request.use(config => ({
      transitional: {
        clarifyTimeoutError: true,
      },
      ...config,
    }));

    this._instance.interceptors.response.use(
      response => response,
      (error: AxiosError) => rejectedResponseInterceptor(error, this.instance),
    );
  }

  get<T = TRes, R = Res<T>, D = Data>(url: string, config?: AxiosRequestConfig<D>) {
    return this.instance.get<T, R, D>(url, config);
  }

  post<T = TRes, R = Res<T>, D = Data>(url: string, data?: D, config?: AxiosRequestConfig<D>) {
    return this.instance.post<T, R, D>(url, data, config);
  }

  put<T = TRes, R = Res<T>, D = Data>(url: string, data?: D, config?: AxiosRequestConfig<D>) {
    return this.instance.put<T, R, D>(url, data, config);
  }

  useRequestInterceptor<D = Data>(
    onRequest: ((value: InternalAxiosRequestConfig<D>) =>
      InternalAxiosRequestConfig<D> | Promise<InternalAxiosRequestConfig<D>>),
    onRejected?: (error: AxiosError) => unknown,
  ) {
    this._instance.interceptors.request.use(onRequest, onRejected);

    return this;
  }

  useTokenInterceptor(token: string) {
    this._instance.interceptors.request.use(config => {
      config.headers.setAuthorization(`JWT ${ token }`);

      return config;
    });

    return this;
  }
}

export const injectAxios = (baseUrl: string, timeout?: number) => new Axios(baseUrl, timeout);
